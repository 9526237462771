/*
Theme Name: CITYTOURS  v.5.1
Theme URI: http://www.ansonika.com/citytours/
Author: Ansonika
Author URI: http://themeforest.net/user/Ansonika/

[Table of contents] 

1. SITE STRUCTURE and TYPOGRAPHY
- 1.1 Typography
- 1.2 Buttons
- 1.3 Structure
- 1.4 Menu

2. CONTENT
- 2.1 Home
- 2.2 All tours list / All tours grid
- 2.3 Single tour page
- 2.4 Cart - Payment - Confirmation
- 2.5 About
- 2.6 Contact us
- 2.7 Login / register
- 2.8 Tour guide page
- 2.9 Transfer
- 3.0 Hotel
- 3.1 Whishlist
- 3.2 Map listing

3. COMMON
- Tooltips
- Containers styles
- Form styles
- Tabs, collapse
- etc

4. UPDATES 
- 4.1 Home 7
- 4.2 Faq
- 4.3 Pricing tables
- 4.4 Common

5. UPDATES
- 5.1 Ribbons css
- 5.2 Footers versions
- 5.3 Header versions
- 5.4 Gallery overlay
- 5.5 Map + infobox

6. UPDATES
- 6.1 Footer Fixed
- 6.2 Notify
- 6.3 Search modal
- 6.4 Header 7

7. UPDATES
- 7.1 Date dropper/Time dropper
- 7.2 Tabs home
- 7.3 Morphext
- 7.4 Cookie-bar 
- 7.5 Sing In Modal
- 7.6 Styles Switcher

8. UPDATES
- 8.1 Home
- 8.2 Misc


9. RESPONSIVE

/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/

/*-------- 1.1 Typography --------*/
body {background:#f9f9f9; font-size:13px; line-height:20px; font-family:"Montserrat", Arial, sans-serif; color:#555;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}

h1, h2, h3, h4, h5, h6 {-webkit-font-smoothing:antialiased; color:#333;}
h3{font-size:22px;}
h4 {font-size: 18px;}

h1, h2, h3 {margin-top: 20px;margin-bottom: 10px;}

.main_title {text-align:center; font-size:16px; margin-bottom:30px;}
.main_title h2 {text-transform: uppercase; font-weight:700; letter-spacing:-1px; font-size:30px; margin-bottom:0;margin-top:0; }
.main_title p {font-weight:300; font-size:20px; color: #555;}

h2 span, h3 span, h4 span, h5 span, h6 span{color:#e04f67;}

.box_style_1 h3.inner{ 
	margin:-30px -30px 20px -30px; 
	background-color:#565a5c; 
	padding:10px 20px 10px 18px;
	color:#fff; 
	border:1px solid #fff;
	-webkit-border-top-left-radius: 3px;
	text-align:center;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

/*General links color*/
a {color: #e14d67;text-decoration: none;-webkit-transition: all 0.2s ease;transition: all 0.2s ease; outline:none;}
a:hover,a:focus {color: #111;text-decoration: none; outline:none;}

p {margin: 0 0 20px 0;}

.nopadding {
	margin: 0 !important;
	padding: 0 !important;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #ddd;
}

/*-------- 1.2 Buttons --------*/
a.button_intro, .button_intro  {
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff !important;
	background: #e04f67;
	cursor: pointer;
	padding: 8px 25px;
	display: inline-block;
	outline: none;
	font-size:12px;
	text-align:center;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	min-width:150px;
}
a.button_intro:hover, .button_intro:hover {
	background: #fff;
	color:#e04f67 !important;
}
a.button_intro.outilne, .button_intro.outline  {
	border: 2px solid #fff;
	color:#fff;
	background: none;
	padding: 6px 23px;
}
a.button_intro:hover, .button_intro:hover {
	background: #fff;
	color:#e04f67;
}

a.button_drop, .button_drop  {
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#e04f67 !important;
	background: #ededed;
	cursor: pointer;
	padding: 5px 15px !important;
	display: inline-block;
	outline: none;
	font-size:11px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	text-align:center;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold !important;
	width:49%;
	white-space: nowrap;
}

a.button_drop.outilne, .button_drop.outline  {
	border: 2px solid #ededed;
	color:#e04f67;
	background: none;
	padding: 3px 23px !important;
}
a.button_drop:hover, .button_drop:hover {
	background:#ededed;
	color:#333;
}
a.button_drop.outline:hover, .button_drop.outline:hover {
	background:#ededed;
	color:#333;
	border: 2px solid #ededed;
}

a.btn_1, .btn_1{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background: #008489;
	cursor: pointer;
	padding: 7px 20px;
	display: inline-block;
	outline: none;
	font-size:13px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	font-weight:bold;
}
a.btn_1.green, .btn_1.green  {background: #83c99f;}

a.btn_1.white, .btn_1.white  {background: #fff; color:#e04f67;}
a.btn_1.white:hover, .btn_1.white a:hover  {background: #333; color:#fff;}

a.btn_1.outline,.btn_1.outiline, input .btn_1.outiline{
	color:#555;
	background: none;
	border:2px solid #555;
	padding: 5px 18px;
}
a.btn_1.outline:hover,.btn_1.outiline:hover, input .btn_1.outiline:hover{
	color:#fff;
	background:#e04f67;
	border:2px solid #e04f67;
}

a.btn_1.medium, .btn_1.medium  {
	padding: 10px 25px;
	font-size:14px;
}
a.btn_1.small, .btn_1.small  {
	padding: 5px 8px;
	font-size:10px;
	line-height:9px;
	text-transform:none;
	color:#fff !important;
}
a.btn_1:hover, .btn_1:hover {
	background: #333;
}

a.btn_map{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background: #e04f67;
	cursor: pointer;
	padding: 12px 20px;
	display: inline-block;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
}
a.btn_map:hover{
	background:#333;
}

a.btn_full, .btn_full{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	width:100%;
	background: #008489;
	cursor: pointer;
	padding: 12px 20px;
	display: inline-block;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
	margin-bottom:10px;
}
a.btn_full:hover, .btn_full:hover{
	background:#333;
}

a.btn_full_outline{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#008489;
	background:none;
	border:2px solid #008489;
	cursor: pointer;
	padding: 10px 20px;
	display: inline-block;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
}
a.btn_full_outline:hover{
	border:2px solid #333;
	color:#333;
}

a.btn_login, .btn_login{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	width:100%;
	background: #008489;
	cursor: pointer;
	padding: 12px 20px;
	display: inline-block;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
	margin-bottom:10px;
}
a.btn_login:hover, .btn_login:hover{
	background:#333;
}


/*-------- 1.3 Structure --------*/

/* Header */
#logo {margin-top:10px;}

header{
	width:100%;
	position:fixed;
	left:0;
	top:0;
	z-index:99999;
	padding:10px 0;
}
header#plain{background-color:#fff;}

header #logo .logo_sticky{ display:none;}
header.sticky #logo .logo_normal{ display:none;}
header.sticky #logo .logo_sticky{ display:block; }

/* logo home with h1 */
#logo_home h1{
	margin:10px 0 0 0;
	padding:0;
}
#logo_home h1 a, header.sticky #logo_home h1 a, header#plain #logo_home h1 a, header#colored #logo_home h1 a{
	width:160px;
	height:34px;
	display:block;
	background-image:url(../img/logo.png);
	background-repeat:no-repeat;
	background-position:left top;
	background-size: 160px 34px;
	text-indent:-9999px;
}
header.sticky #logo_home h1{
	margin:0 0 10px 0;
	padding:0;
}
header.sticky #logo_home h1 a{
	background-image:url(../img/logo_sticky.png);
}
header#plain #logo_home h1 a{
	background-image:url(../img/logo_sticky.png);
}
header.sticky#colored #logo_home h1 a{
	background-image:url(../img/logo_sticky_colored.png);
}
@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
#logo_home h1 a, header#colored #logo_home h1 a{
    background-image: url(../img/logo_2x.png);
	background-size: 160px 34px;
}
header.sticky #logo_home h1 a, header#plain #logo_home h1 a{
    background-image: url(../img/logo_sticky_2x.png);
	background-size: 160px 34px;
}
header.sticky#colored #logo_home h1 a{
    background-image: url(../img/logo_sticky_colored_2x.png);
	background-size: 160px 34px;
}
}

header.sticky #top_line { 
	height: 0;
	overflow:hidden; 
	padding:0; 
	opacity:0;
	transition: all 0.2s ease-in-out;
	 -moz-transition: all 0.2s ease-in-out;
	 -webkit-transition: all 0.2s ease-in-out;
	 -o-transition: all 0.2s ease-in-out;
}

header.sticky{ 
	-webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	-moz-box-shadow:0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	box-shadow:0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	background-color:#fff; 
	padding-bottom:0;
}

nav{ margin-top:20px; position:relative;}
.sticky nav{ margin-top:10px}
.sticky #logo{ margin-top:0; margin-bottom:10px;}

ul#top_tools{list-style:none; padding:0; position:absolute; right:15px; top:0; z-index:999;}
ul#top_tools a{ color:#fff;}
ul#top_tools a:hover {color:#e04f67;}
.sticky ul#top_tools a{ color:#333; }
ul#top_tools li{display:inline-block; padding:0 10px;}
ul#top_tools li i{font-size: 18px; font-weight: normal;}

#top_line{
	color:#fff;
	height:28px;
	font-size:12px;
	border-bottom:1px solid rgba(255,255,255,0.2);
	 font-size:11px;
	 visibility:visible;
	 opacity:1;
	 margin-bottom:5px;
	 position:relative;
	 z-index:999999;
}
ul#top_links{
	list-style:none;
	margin:0;
	padding:0;
	float:right;
}
a#access_link, a#wishlist_link{
	position:relative;
	display:inline-block;
	padding-left:16px;
}
#access_link:before, #wishlist_link:before{
	font-style: normal;
 	font-weight: normal;
 	font-family: "fontello";
    font-size:14px;
	position:absolute;
	left:0;
	top:0;
}

#wishlist_link:before{content: "\ec5e";}
#access_link:before{content: "\e97f";}

ul#top_links li{
	display:inline-block;
	border-left: 1px solid rgba(255,255,255,0.3);
	margin-right:5px;
	padding-left:8px;
	position:relative;
}
ul#top_links li:first-child{
	border-left: none;
	padding-left:0;
}
ul#top_links a{
	color:#fff;
}
ul#top_links a:hover{
	color:#e04f67;
}

/* Header  plain*/
header#plain{background-color:#fff;padding-bottom:0;}
header#plain.sticky{
	-webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	-moz-box-shadow:    0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	box-shadow:         0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	background-color:#fff; 
	position:fixed;
	left:0;
	top:0;
	z-index:99999;
}
header#plain.sticky #logo .logo_normal{ display:none;}
header#plain.sticky #logo .logo_sticky{ display:block; }
header#plain #top_line{color:#888;border-bottom:1px solid rgba(0,0,0,0.1);}
header#plain ul#top_links li{border-left: 1px solid rgba(0,0,0,0.1);}
header#plain ul#top_links li:first-child{border-left:none;}
header#plain ul#top_tools a{ color:#333; }
header#plain ul#top_links a{ color:#888; }
header#plain ul#top_links :hover a{ color:#333; }

.subheader_plain{margin-top:60px;}

/* Header  colored*/
header#colored.sticky{background-color:#e04f67;padding-bottom:0; border-bottom:2px solid #e04f67}
header#colored.sticky ul#top_tools a {color:#fff;}


.dropdown-cart .dropdown-menu{
	border: none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	min-width: 0;
	padding: 15px;
	width: 260px;
	top: 100%;
	margin-left: -217px;
	font-size: 12px;
	font-weight: normal;
	text-shadow: none;
	text-transform: none !important;
	border-top: 2px solid #e04f67;
	-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.dropdown-cart .dropdown-menu:before {
	bottom: 100%;
	right: 10%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: #e04f67;
	border-width: 7px;
	margin-left: -7px;
}
.dropdown-cart .dropdown-menu{
	margin-top:12px;
}

a.cart_bt{
	position: relative;
}

a.cart_bt strong {
    font-size: 11px;
	width: 15px;
	height: 15px;
    display: block;
    background-color: #e04f67;
    color: #fff;
	text-align: center;
    position: absolute;
    bottom: -5px;
    right: -8px;
    line-height: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

ul#cart_items{
	padding:5px 0 0 0;
	list-style:none;
}
ul#cart_items li{
	border-bottom:1px solid #ededed;
	position:relative;
	margin:0;
	padding:5px 10px;
	padding-bottom:10px;
	width:100%;
	clear:both;
}
ul#cart_items li .image{
   overflow:hidden;
   width:50px;
   height:50px;
   float:left;
   margin-right:10px;
}
ul#cart_items li .image img{
  width:48px;
  height:auto;
}
ul#cart_items li strong {
	font-weight:normal;
	float:left;
	color:#999;
	margin-top:10px;
	float:left;
	width:150px;
	line-height:14px;
}
ul#cart_items li strong > a{
	color:#333;
	display: block;
}
ul#cart_items li strong > a:hover{
	color:#e04f67;
}
ul#cart_items li a.action {
	color: #999;
	position: absolute;
	right: 15px;
	top: 5px;
	width: 15px;
	height: 15px;
}
ul#cart_items li a.action i{
	font-size: 14px;
	text-shadow: none;
	font-weight: normal;
}
ul#cart_items li a.action:hover {
	color:#333;
	background:none;
}
ul#cart_items li:last-child div{
  color: #999;
  text-align:right;
  font-size:14px;
  padding:10px 0 15px 0;
  display:block;
}
ul#cart_items li:last-child span{
    color: #333;
	font-weight:bold;
	font-size:16px;
}
.dropdown-cart .dropdown-menu a.button_drop{
	display:inline-block;
}
.dropdown- .dropdown-menu{
	margin-top:7px;
}

.input-group button {
	background-color: #333;
	color: #fff;
	border-color: #333;
	border-top-left-radius: 0;
    border-bottom-left-radius: 0;
	font-size: 14px;
	outline: none;
}

/* Footer */
footer {
	background: #222 url(../img/pattern.png) repeat 0 0;
	color: #fff;
	padding: 30px 0 10px 0;
}

footer h3 {
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	margin-top: 20px;
}

footer a {
	color: #fff;
}

footer a:hover {
	color: #999;
}

footer ul {
	margin: 0;
	padding: 0 0 10px 0;
	list-style: none;
}

#social_footer {
	text-align: center;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 30px;
	margin-top: 30px;
}

#social_footer p {
	font-size: 12px;
	color: #8c8c8c;
}

#social_footer ul {
	margin: 0;
	padding: 0 0 10px 0;
	text-align: center;
}

#social_footer ul li {
	display: inline-block;
	margin: 0 5px 10px 5px;
}

#social_footer ul li a {
	color: #fff;
	text-align: center;
	line-height: 35px;
	display: block;
	font-size: 16px;
	width: 35px;
	height: 35px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

#social_footer ul li a:hover {
	background: #fff;
	color: #111;
}

a#phone,
a#email_footer {
	display: block;
	position: relative;
	color: #fdf7ac;
	font-size: 16px;
	padding-left: 40px;
	margin: 20px 0;
}

a#phone:hover,
a#email_footer:hover {
	color: #fff;
}

a#phone:before,
a#email_footer:before {
	font-style: normal;
	font-weight: normal;
	font-family: "icon_set_1";
	position: absolute;
}

a#phone:before {
	font-size: 30px;
	content: "\79";
	left: 0;
	top: 0;
}

a#email_footer:before {
	font-size: 28px;
	content: "\74";
	left: 0;
	top: 5px;
}

/*-------- 1.4 menu --------*/
.main-menu {position: relative;z-index:9;width:auto;}
.main-menu a {-webkit-transition:all 0.3s;-moz-transition:all 0.3s;transition:all 0.3s;}
.main-menu ul,
.main-menu ul li,
.main-menu ul li a {position: relative; margin-bottom:0; margin: 0;padding: 0;}

/* Submenu styles */
.main-menu ul li a {display: block; line-height: 20px;padding:10px;}
			
/*First level styles */			
.main-menu > ul > li > a {color: #fff; padding: 0 8px 15px  8px; font-size:13px;}
.sticky .main-menu > ul > li > a {color: #333;}
.sticky .main-menu > ul > li:hover > a {color: #e04f67;}
.main-menu > ul > li:hover > a {}

/*First level styles header plain */		
header#plain .main-menu > ul > li > a {color: #333;}
header#plain .main-menu > ul > li:hover > a {color: #e04f67;}

/*First level styles header colored */		
header#colored.sticky .main-menu > ul > li > a {color: #fff;}

/* Opacity mask when left open */
.layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    z-index: 100;
    min-height: 100%;
    background-color: #000;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
    -moz-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
    -ms-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
    -o-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
    opacity: 0;
    visibility: hidden;
}
.layer-is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
    -moz-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
    -ms-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
}

#close_in,#header_menu, .cmn-toggle-switch{display:none;}	

a.dropdown-toggle.icon-search{display: inline-block;}

/* All styles for screen size between 992px and 1200px
================================================== */
@media (min-width: 992px) and (max-width: 1200px) {
	
/*First level styles */			
.main-menu > ul > li > a {padding: 0 5px 15px  5px;}

}
		
/* All styles for screen size over 992px
================================================== */
@media only screen and (min-width: 992px) {
	
.main-menu {width: auto;}
.main-menu a {white-space: nowrap;}	
.main-menu ul li {display: inline-block;}

.main-menu ul li.submenu:hover > a:before,
.main-menu ul li.submenu:hover > a:after {bottom: -20px;opacity: 0;}
		
/* Submenu + megamenu*/
.main-menu ul ul, .main-menu ul li .menu-wrapper {
	position: absolute;
	border-top: 2px solid #e04f67;
	z-index: 1;
	visibility: hidden;
	left: 3px;
	top: 100%;
	margin: 0;
	display:block;
	padding: 0;
	background: #fff;
	min-width:210px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	-webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
	}
.main-menu ul li .menu-wrapper {
	padding:10px 15px !important;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	}
.main-menu ul li:hover > ul, .main-menu ul li:hover .menu-wrapper{
	padding: 0;
	visibility:visible;
	-webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
	}
.main-menu ul .menu-wrapper ul {
	-webkit-box-shadow:none;
	-moz-box-shadow:none;
	box-shadow:none;
	border-top: none; 
	margin:0; 
	position:static; 
	-webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
	}
.main-menu ul .menu-wrapper ul:before{border:0;}

.main-menu ul ul li {display: block;height: auto;padding: 0;}		
.main-menu ul ul li a {font-size: 12px;color: #666;border-bottom:1px solid #ededed;display: block;}
.main-menu ul ul li:last-child a {border-bottom:none;}

.main-menu ul ul li:hover > a {background-color:#f9f9f9;color:#e04f67; padding-left:15px;}

/* Megamenu */
.main-menu ul li.megamenu {position:static;}
.main-menu ul li.megamenu .menu-wrapper {width:1115px;float:left;left:auto;right:0; padding: 10px 30px 15px 30px!important;}
.main-menu ul li:hover.megamenu .menu-wrapper {visibility:visible; opacity:1;}
.main-menu ul li:hover.megamenu .menu-wrapper ul{visibility:visible; opacity:1;}

.main-menu ul .menu-wrapper h3 {font-size: 13px;text-transform:uppercase;border-bottom:2px solid #ededed;padding-bottom:10px;margin-bottom:0;}

/* Submenu 3rd level */
.main-menu ul ul ul{position: absolute;border-top: 0;z-index: 1;height: auto;left: 100%;top: 0;margin: 0;padding: 0;background: #fff;min-width:190px;-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);box-shadow: 0 6px 12px rgba(0, 0, 0, .175);}	
.main-menu ul ul:before{bottom: 100%;left: 15%;border: solid transparent;content: " ";height: 0;width: 0;position: absolute;pointer-events: none;border-bottom-color: #e04f67;border-width: 7px;margin-left: -7px;}
.main-menu ul ul ul:before{border-width: 0;margin-left: 0;}

 
 .main-menu ul ul li.third-level > a:hover {
    background-color: #f9f9f9;
    color: #e04f67;
    padding-left: 18px;
    opacity: 1;
  }
  .main-menu ul ul li.third-level > a:after {
    font-family: 'ElegantIcons';
    content: "\35";
    float: right;
    font-size: 16px;
    font-size: 1rem;
    margin-top: -1px;
  }
	
}

@media only screen and (max-width: 991px) {
	
#header_menu{text-align:center;padding:25px 15px 10px 15px;position:relative;display:block;}

.main-menu ul li a:hover,
a.show-submenu:hover,
a.show-submenu:focus,
a.show-submenu-mega:hover,
a.show-submenu-mega:focus{color:#e04f67 !important;background-color:#f9f9f9;}	
	
.main-menu ul li  {border-top: none;border-bottom: 1px solid #ededed;color: #fff;}

/* Menu mobile first level */	
.main-menu ul li a  {padding:10px 15px !important;}

.main-menu h3 {font-size: 12px; line-height:14px;margin: 0;padding: 0 0 15px 15px;color: #333;text-transform:uppercase;}
.megamenu .menu-wrapper > div {padding:0;}	
	
.main-menu li,
.main-menu a {display: block;color:#333 !important;}
.main-menu li {position: relative;}
	
.main-menu a:hover {color:#e04f67 !important;}
	
.main-menu ul > li {padding-bottom: 0;}
.main-menu ul > li i{float:right;}

/* Menu mobile second level */	
.main-menu ul li.submenu ul{font-size: 12px;border-left:1px solid #ededed;margin:0 0 15px 25px;}
.main-menu ul li.submenu ul li{font-size: 12px;border:0;}

/* Menu mobile 3rd level */	
.main-menu ul li.submenu ul ul{margin:0 0 0 25px;}


/* Menu mobile left panel */	
.main-menu {
	overflow: auto;
	transform: translateX(-105%);
	top:0;
	left:0;
	bottom: 0;
	width: 55%;
	height: 100%;
	position:fixed;
	background-color:#fff;
	z-index:999999;
	-webkit-box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
	-moz-box-shadow:    1px 0px 5px 0px rgba(50, 50, 50, 0.55);
	box-shadow:         1px 0px 5px 0px rgba(50, 50, 50, 0.55);
	-webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}
.main-menu.show {transform: translateX(0);}

.main-menu .show-submenu + ul,
a.show-submenu-mega + .menu-wrapper{display: none;visibility: hidden;}
a.show-submenu-mega + .menu-wrapper.show_mega,
.main-menu a.show-submenu + ul.show_normal  {display: block;visibility: visible;}
	
/* Hamburger menu button*/
.cmn-toggle-switch {
  position: relative;
  display:block;
  overflow: visible;
  position:absolute; 
  top:0;
  right:20px;
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.cmn-toggle-switch:focus {outline: none;}
.cmn-toggle-switch span {display: block;position: absolute;top: 10px;left: 0;right: 0;height: 2px;background: white;}

.cmn-toggle-switch span::before,
.cmn-toggle-switch span::after {position: absolute;display: block;left: 0;width: 100%;height:2px;background-color: #fff; content: "";}

.sticky .cmn-toggle-switch span::before,
.sticky .cmn-toggle-switch span::after,
.sticky .cmn-toggle-switch span {background-color: #333;}

/* Header plain */
header#plain .cmn-toggle-switch span::before,
header#plain .cmn-toggle-switch span::after,
header#plain .cmn-toggle-switch span,
header#plain.sticky .cmn-toggle-switch span::before,
header#plain.sticky .cmn-toggle-switch span::after {background-color: #333;}

/* Header transparent colored */
header#colored.sticky .cmn-toggle-switch span::before,
header#colored.sticky .cmn-toggle-switch span::after, header#colored.sticky .cmn-toggle-switch span {background-color: #fff;}

.cmn-toggle-switch span::before {top: -10px;}
.cmn-toggle-switch span::after {bottom: -10px;}

.cmn-toggle-switch__htx span::before,
.cmn-toggle-switch__htx span::after { -webkit-transition-duration: 0.3s, 0.3s;transition-duration: 0.3s, 0.3s;-webkit-transition-delay: 0.3s, 0;transition-delay: 0.3s, 0;}
.cmn-toggle-switch__htx span::before { -webkit-transition-property: top, -webkit-transform;transition-property: top, transform;}
.cmn-toggle-switch__htx span::after {-webkit-transition-property: bottom, -webkit-transform;transition-property: bottom, transform;}

/* active state, i.e. menu open */
.cmn-toggle-switch__htx.active span{background:none !important;}
.cmn-toggle-switch__htx.active span::before {top: 0; -webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}
.cmn-toggle-switch__htx.active span::after {bottom: 0;-webkit-transform: rotate(-45deg); -ms-transform: rotate(-45deg);transform: rotate(-45deg);}
.cmn-toggle-switch__htx.active span::before,
.cmn-toggle-switch__htx.active span::after {-webkit-transition-delay: 0, 0.3s;transition-delay: 0, 0.3s;}

}

@media only screen and (max-width: 480px) {
	
.main-menu {width: 100%;}
a#close_in {display:block;position:absolute;right:15px;top:10px;width:20px;height:20px;}
#close_in i{color:#555 !important;font-size:16px;}

}

/*============================================================================================*/
/* 2.  CONTENT  */
/*============================================================================================*/

/*-------- 2.1 Home --------*/
.feature_home {
	padding: 30px;
	position: relative;
	background: #fff;
	margin-bottom: 30px;
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.feature_home h3 {
	font-size: 20px;
}

.feature_home i {
	margin: auto;
	margin-bottom: 20px;
	display: block;
	width: 120px;
	height: 120px;
	line-height: 110px;
	text-align: center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px dashed #ccc;
	font-size: 62px;
	color: #6dcff6;
}

.other_tours ul {
	list-style: none;
	padding: 0;
	margin: 0 0 0 0;
}

.other_tours ul li a {
	border-bottom: 1px solid #ededed;
	padding: 5px 0 10px 0;
	display: block;
	color: #555;
}

.other_tours ul li a:hover {
	background-color: #f9f9f9;
	color: #e04f67;
}


.other_tours ul li a i {
	font-size: 22px;
	margin-right: 5px;
	margin-left: 2px;
	position: relative;
	top: 5px;
	color: #555;
}

.other_tours_price {
	float: right;
	margin-top: 5px;
	margin-right: 5px;
	color: #888;
}

/* Home 2 */
#hero {
	position: relative;
	height: 600px;
	background: #4d536d url(../img/slide_hero.jpg) no-repeat center center;
	background-size: cover;
	color: #fff;
	width: 100%;
	font-size: 16px;
	display: table;
	z-index: 99;
	text-align: center;
	text-transform: uppercase;
}

.intro_title {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
}

.intro_title h3 {
	font-size: 45px;
	margin-bottom: 5px;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
}

.intro_title h3 strong span {
	color: #fff;
}

/* New v1.2*/
.intro_title.error h1 {
	font-size: 130px;
	color: #fff;
	font-weight: bold;
}

/* Home 3 */
.header-video {
	position: relative;
	overflow: hidden;
	background: #4d536d url(../img/slide_hero.jpg) no-repeat center center;
	background-size: cover;
}

#hero_video {
	position: relative;
	background-size: cover;
	color: #fff;
	width: 100%;
	font-size: 16px;
	display: table;
	height: 100%;
	z-index: 99;
	text-align: center;
	text-transform: uppercase;
}

#hero_video a.video {
	display: none;
}

iframe,
video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

iframe {
	height: 100%;
	width: 100%;
}

video {
	width: 100%;
}

.teaser-video {
	width: 100%;
	height: auto;
}

.header-video--media {
	width: 100%;
	height: auto;
}

/* Home 5 */
#search_bar_container {
	background: #333;
	background-color: rgba(0, 0, 0, 0.4);
	padding: 15px 0;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	text-align: center;
}

.search_bar {
	position: relative;
	width: 80%;
	margin: auto;
}

#nav-search-in {
	display: inline-block;
	height: 45px;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	background: #6ccef5;
}

#nav-search-in #nav-search-in-content {
	color: #fff;
	display: inline-block;
	font-size: 12px;
	text-transform: uppercase;
	height: 45px;
	line-height: 35px;
	margin: 5px 65px 0 15px;
	text-indent: 1px;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 60px;
	overflow: visible;
}

#nav-search-in.nav-facade-active .nav-down-arrow {
	display: inline-block;
	right: 15px;
	top: 20px;
	background: url(../img/arrow_down_2.png);
	height: 6px;
	width: 10px;
	position: absolute;
}

.nav-facade-active #searchDropdownBox {
	cursor: pointer;
	height: 45px;
	left: 15px;
	opacity: 0;
	top: 0;
	visibility: visible;
	width: auto;
}

#searchDropdownBox {
	background: none repeat scroll 0 0 white;
	border: 1px solid #DDDDDD;
	color: black;
	font-family: "arial", "sans-serif";
	left: 5px;
	margin: 0;
	outline: medium none;
	padding: 0;
	position: absolute;
	top: 0;
	visibility: hidden;
	width: 100px;
}

.nav-searchfield-outer {
	border: none;
	background: #fff;
	color: #ccc;
	display: block;
	font-size: 15px;
	left: 0;
	padding: 5px;
	width: 100%;
	border: none;
	height: 45px;
	font-weight: 400;
	margin-top: 1px;
}

#twotabsearchtextbox {
	background: #FFF;
	width: 100%;
	border: none;
	box-shadow: none;
	color: #333;
	font-size: 14px;
	height: 30px;
	padding: 3px 0 0 150px;
	outline: none;
}

.nav-submit-button {
	background: #fff url(../img/search-bt.png) no-repeat center center;
	border: none;
	cursor: pointer;
	display: block;
	height: 45px;
	right: 5px;
	position: absolute;
	text-indent: -9999px;
	top: 0;
	width: 40px;
}

.nav-submit-button .nav-submit-input {
	background: #fff;
	border: medium none;
	color: white;
	cursor: pointer;
}

/*-------- 2.2 All tours list / All tours grid --------*/
.box_style_cat {
	background: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	border: 1px solid #ddd;
	margin-bottom: 25px;
}

ul#cat_nav {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul#cat_nav li {
	border-bottom: 1px solid #ddd;
}

ul#cat_nav li a {
	position: relative;
	color: #777;
}

ul#cat_nav li a span {
	font-size: 11px;
	color: #999;
}

ul#cat_nav li a:after {
	font-family: "fontello";
	content: "\e89b";
	position: absolute;
	right: 15px;
	top: 15px;
}

ul#cat_nav li:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

ul#cat_nav li:first-child a:hover,
ul#cat_nav li:first-child a#active,
ul#cat_nav li:first-child a.active {
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

ul#cat_nav li:last-child a:hover,
ul#cat_nav li:last-child a#active,
ul#cat_nav li:last-child a.active {
	-webkit-border-bottom-right-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-moz-border-radius-bottomright: 3px;
	-moz-border-radius-bottomleft: 3px;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

ul#cat_nav li a {
	display: block;
	padding: 15px 10px;
}

ul#cat_nav li a:hover,
ul#cat_nav li a#active,
ul#cat_nav li a.active {
	background: #f9f9f9;
	color: #111;
}

ul#cat_nav i {
	font-size: 22px;
	margin-right: 5px;
	float: left;
}

#tools {
	background: #eee;
	padding: 5px;
	margin-bottom: 15px;
}

/* Map */
.marker_info {
	width: 280px;
	height: 320px;
	border-radius: 3px;
	text-align: center;
	background: #FFF;
	background: rgba(255, 255, 255, 0.9);
	position: relative;
	z-index: 9999999;
	font-family: "Montserrat", Arial, sans-serif;
}

.marker_info img {
	display: block;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.marker_info h3 {
	font-size: 18px;
	line-height: 18px;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.marker_info span {
	display: block;
	padding: 0px 20px;
	font-size: 12px;
	line-height: 16px;
	color: #7a7f82;
	margin-bottom: 0;
}

.marker_info:after {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-right-color: rgba(255, 255, 255, 0.9);
	border-width: 10px;
	margin-top: -10px;
}

.strip_all_tour_list {
	margin-bottom: 30px;
	background-color: #fff;
	display: block;
	color: #777;
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.strip_all_tour_list h3 {
	text-transform: uppercase;
}

.tour_list_desc .rating {
	margin: 15px 0 3px -3px;
	font-size: 15px;
}

.img_list {
	overflow: hidden;
	min-height: 220px;
	text-align: center;
	position: relative;
}

.img_list img {
	width: auto;
	height: 220px;
	position: absolute;
	left: -10%;
}

.img_list a img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-webkit-backface-visibility: hidden;
}

.img_list a:hover img {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.img_list .short_info {
	padding: 5px 5px 5px 5px;
	text-align: left;
	min-height: 38px;
}

.price_list .button {
	display: block;
}

.tour_list_desc {
	padding: 10px 20px 0 0;
	border-right: 1px solid #ededed;
	height: 220px;
	line-height: 17px;
}

.tour_list_desc h3 {
	font-size: 16px;
	margin-top: 0;
	margin-bottom: 10px;
}

.price_list {
	display: table;
	height: 220px;
	font-size: 38px;
	color: #e74c3c;
	width: 100%;
	margin-left: -15px;
}

.price_list small {
	font-size: 11px;
	color: #999;
	display: block;
	margin-bottom: 10px;
	line-height: 12px;
}

.price_list div {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.price_list p {
	padding: 0;
	margin: 0;
}

.price_list .normal_price_list {
	display: block;
	text-decoration: line-through;
	color: #999;
	font-size: 20px;
	margin-top: 10px;
}

.price_list sup {
	font-size: 22px;
	position: relative;
	top: -10px;
}

ul.add_info {
	list-style: none;
	padding: 0;
	margin: 0;
}

ul.add_info li {
	display: inline-block;
	margin-right: 5px;
	border: 1px solid #ededed;
	text-align: center;
	width: 35px;
	height: 35px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

ul.add_info li a {
	color: #555;
	width: 35px;
	height: 35px;
	display: block;
}

ul.add_info li i {
	display: inline-block;
	font-size: 22px;
	top: 6px;
	position: relative;
}

#filters_col {
	background-color: #fff;
	padding: 15px 10px 15px 15px;
	border: 1px solid #ddd;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	margin-bottom: 25px;
}

#filters_col label {
	color: #777;
	font-weight: normal;
}

a#filters_col_bt {
	display: block;
	color: #888;
}

a#filters_col_bt .icon_set_1_icon-65 {
	font-size: 20px;
	position: relative;
	top: 0;
	margin-right: 5px;
}

.filter_type h6 {
	border-top: 1px solid #ddd;
	margin: 15px 0;
	padding: 15px 0 0 0;
	font-size: 12px;
}

.filter_type ul {
	list-style: none;
	padding: 0;
	margin: 0 0 15px 0;
}

.filter_type ul li .rating {
	font-size: 16px;
	left: -7px;
	position: relative;
	top: 3px;
}

/*-------- 2.3 Single tour page --------*/
#Img_carousel .sp-selected-thumbnail {
	border-bottom: 2px solid #e04f67;
}

.parallax-content-2 {
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 999;
	padding: 25px 0 20px 0;
	color: #fff;
	font-size: 13px;
	background: -webkit-linear-gradient(top,transparent,#000);
    background: linear-gradient(to bottom,transparent,#000);
	width: 100%;
}

.parallax-content-2 div h1 {
	font-size: 36px;
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
	margin: 0;
}

.parallax-content-2 .rating {
	font-size: 14px;
}

#price_single_main {
	text-align: right;
	padding-top: 25px;
	font-weight: normal;
}

#price_single_main span {
	font-size: 60px;
	color: #3CF;
}

#price_single_main sup {
	font-size: 22px;
	position: relative;
	top: -25px;
}

#price_single {
	background-color: #e74c3c;
	color: #fff;
	font-size: 36px;
	margin: -15px;
	padding: 20px 15px 10px 15px;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	margin-bottom: 15px;
}

#price_single small {
	font-size: 12px;
}

#single_tour_feat ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: table;
	border-bottom: 2px solid #555;
	margin-bottom: 30px;
}

#single_tour_feat ul li {
	display: table-cell;
	width: 1%;
	vertical-align: middle;
	text-align: center;
	padding: 0 10px 20px 10px;
	white-space: nowrap;
}

#single_tour_feat ul li:last-child {
	margin-right: 0;
}

#single_tour_feat ul li i {
	font-size: 36px;
	display: block;
	margin-bottom: 10px;
}

#single_tour_desc h3 {
	font-size: 22px;
	margin-top: 5px;
}

#single_tour_desc h4 {
	line-height: 20px;
	font-size: 18px;
}

#map {
	width: 100%;
	height: 450px;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
}

.table td, .table th {
    border-top: none;
}
.table.table_summary td {
	border-top: 1px solid #ededed;
	width: 50%;
}

.table.table_summary tr.total {
	color: #e04f67;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	white-space: nowrap;
}

.review_strip_single {
	position: relative;
	padding: 30px 0 20px 0;
	margin: 30px 0 25px 0;
	border-bottom: 1px solid #ddd;
}

.review_strip_single.last {
	margin-bottom: 0;
	border-bottom: 0;
}

.review_strip_single img {
	top: -15px;
	position: absolute;
	left: 0;
	border: 4px solid #fff;
}

.review_strip_single h4 {
	font-size: 18px;
	margin: -12px 0 35px 90px;
	padding: 0;
}

.review_strip_single small {
	float: right;
	font-size: 12px;
	margin-top: -10px;
	font-style: italic;
}

.review_strip_single .rating {
	font-size: 16px;
}

.normal_price_single {
	font-size: 18px;
	text-decoration: line-through;
}

.price_in {
	font-size: 42px;
	color: #e74c3c;
	border-bottom: 1px solid #ededed;
	text-align: center;
	padding-top: 30px;
	background-color: #fff8e5;
	margin: -20px -20px 20px -20px;
}

.price_in small {
	font-size: 11px;
	color: #999;
	display: block;
	margin-bottom: 20px;
	margin-top: 4px;
}

.price_in p {
	padding: 0;
	margin: 0;
}

.normal_price_in {
	display: block;
	text-decoration: line-through;
	color: #999;
	font-size: 20px;
	margin-top: 10px;
}

.price_in sup {
	font-size: 22px;
	position: relative;
	top: -10px;
}

.table.options_booking td i {
	font-size: 26px;
}

.table.options_booking td {
	vertical-align: middle;
}

/*Update version 1.1*/
#general_rating {
	font-size: 18px;
	margin: 10px 0 20px 0;
}

#general_rating .rating {
	display: inline;
}

#rating_summary ul {
	list-style: none;
	margin: 0 0 -5px 0;
	padding: 0;
}

#rating_summary ul li {
	margin-bottom: 5px;
}

#rating_summary ul li .rating {
	display: inline-block;
	font-size: 14px;
	float: right;
}
/*End Update version 1.1*/

/*-------- 2.4 Cart - Payment - Confirmation --------*/
#hero_2 {
	position: relative;
	height: 470px;
	background: #4d536d url(../img/slide_hero_2.jpg) no-repeat center center;
	background-size: cover;
	color: #fff;
	width: 100%;
	font-size: 16px;
	display: table;
	text-align: center;
	z-index: 1;
}

#hero_2.restaurant_bg {
	background: #4d536d url(../img/restaurant_top_in.jpg) no-repeat center center;
	background-size: cover;
}

#hero_2 .intro_title h1 {
	font-size: 38px;
	margin-bottom: 15px;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
}

#hero_2 .intro_title h1:before,
#hero_2 .intro_title h1:after {
	display: inline-block;
	width: 60px;
	height: 1px;
	content: '';
	vertical-align: middle;
	background: rgba(255, 255, 255, 0.5);
}

#hero_2 .intro_title h1:before {
	margin-right: 10px;
}

#hero_2 .intro_title h1:after {
	margin-left: 10px;
}

.bs-wizard {
	width: 90%;
	margin: auto;
}

.bs-wizard > .bs-wizard-step {
	padding: 0;
	position: relative;
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
	font-size: 16px;
	margin-bottom: 5px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
	color: #999;
	font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
	position: absolute;
	width: 30px;
	height: 30px;
	display: block;
	background: #fbe8aa;
	top: 45px;
	left: 50%;
	margin-top: -15px;
	margin-left: -15px;
	border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
	content: ' ';
	width: 14px;
	height: 14px;
	background: #e04f67;
	border-radius: 50px;
	position: absolute;
	top: 8px;
	left: 8px;
}

.bs-wizard > .bs-wizard-step > .progress {
	position: relative;
	border-radius: 0px;
	height: 8px;
	box-shadow: none;
	margin: 23px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
	width: 0px;
	box-shadow: none;
	background: #fbe8aa;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
	width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
	width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
	width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
	width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
	background-color: #f5f5f5;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
	opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
	left: 50%;
	width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
	width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
	pointer-events: none;
}

.form_title {
	position: relative;
	padding-left: 55px;
	margin-bottom: 10px;
}

.form_title h3 strong {
	background-color: #e04f67;
	text-align: center;
	width: 40px;
	height: 40px;
	display: inline-block;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	color: #fff;
	font-size: 18px;
	line-height: 40px;
	text-align: center;
	position: absolute;
	left: 0;
	top: 0;
}

.form_title h3 {
	margin: 0;
	padding: 0;
}

.form_title p {
	color: #999;
	margin: 0;
	padding: 0;
	font-size: 12px;
	line-height: 14px;
}

.step {
	border-left: 1px solid #ddd;
	padding: 20px 0 20px 31px;
	margin: 0 0 10px 20px;
}

.cards {
	margin-top: 18px;
}

.table.options_cart td i {
	font-size: 38px;
}

.table.cart-list th,
.table.options_cart th {
	background-color: #fff;
	text-transform: uppercase;
}

.table.cart-list td {
	vertical-align: middle;
}

.table.options_cart td {
	vertical-align: middle;
}

.numbers-row {
	position: relative;
	width: 97px;
	height: 40px;
	overflow: visible;
}

.numbers-row.list {
	margin: auto;
	margin-bottom: 5px;
	margin-top: 15px;
}

input.qty2 {
	position: relative;
	width: 35px;
	height: 40px;
	border-radius: none;
	text-align: center;
	left: 31px;
	font-size: 12px;
	padding: 5px;
}

.button_inc {
	text-indent: -9999px;
	cursor: pointer;
	position: absolute;
	width: 33px;
	height: 40px;
	z-index: 9;
}

.dec {
	background: #fff url(../img/minus.png) no-repeat center center;
	border: 1px solid #cccccc;
	left: 0;
	top: 0;
	-webkit-border-top-left-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	-moz-border-radius-topleft: 4px;
	-moz-border-radius-bottomleft: 4px;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.inc {
	background: #fff url(../img/plus.png) no-repeat center center;
	right: 0;
	top: 0;
	border: 1px solid #cccccc;
	-webkit-border-top-right-radius: 4px;
	-webkit-border-bottom-right-radius: 4px;
	-moz-border-radius-topright: 4px;
	-moz-border-radius-bottomright: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

#total {
	text-align: right;
	margin-top: 20px;
}

#total h3 {
	font-size: 26px;
	line-height: 26px;
	padding: 0;
	font-weight: bold;
	display: block;
	margin: 0 0 10px 0;
}

.options i {
	font-size: 22px;
}

.thumb_cart {
	border: 1px solid #ddd;
	overflow: hidden;
	width: 60px;
	height: 60px;
	margin-right: 10px;
	float: left;
}

.thumb_cart img {
	padding: 1px;
	width: 58px;
	height: auto;
}

.item_cart {
	display: inline-block;
	margin-top: 20px;
}

.table.confirm th {
	text-transform: uppercase;
}

.table.confirm td {
	width: 50%;
}

#policy {
	padding: 10px 0 0 50px;
}

/*-------- 2.5 About --------*/
.feature {
	padding: 30px 30px 20px 120px;
	position: relative;
	background: #fff;
	margin-bottom: 30px;
	color: #888;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.feature i {
	margin: 0;
	position: absolute;
	top: 30px;
	left: 30px;
	padding: 0;
	margin: 0;
	width: 65px;
	height: 65px;
	line-height: 60px;
	text-align: center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px dashed #ccc;
	font-size: 34px;
	color: #6dcff6;
}

.features-bg {
	position: relative;
	min-height: 400px;
	background: url(../img/features-intro-01.jpg) no-repeat center center;
	background-size: cover;
}

.features-img {
	width: 100%;
	height: 400px;
	text-align: center;
	line-height: 400px;
}

.features-content {
	position: relative;
	padding: 11% 100px 10% 100px;
	height: 400px;
	background-color: #e04f67;
	color: #fff;
	font-size: 14px;
}

.features-content h3 {
	font-family: "Gochi Hand", cursive;
	color: #fff;
	font-size: 32px;
	line-height: 34px;
	text-transform: uppercase;
}

.review_strip {
	background: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	position: relative;
	padding: 30px 20px 20px 30px;
	margin-bottom: 30px;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.review_strip img {
	top: -15px;
	position: absolute;
	left: 25px;
	border: 4px solid #fff;
}

.review_strip h4 {
	font-size: 18px;
	margin: -10px 0 35px 80px;
	padding: 0;
}

.review_strip p {
	margin-bottom: 10px;
}

.review_strip .rating {
	font-size: 16px;
}

.rating {
	font-size: 18px;
}

.rating small {
	font-size: 12px;
	color: #CCC;
}

.rating .voted {
	color: #F90;
}

/*-------- 2.6 Contact us --------*/
#map_contact {
	width: 100%;
	height: 450px;
}

#directions {
	background-color: #e04f67;
	padding: 22px 0;
}

ul#contact-info {
	list-style: none;
	margin: 0 0 0 0;
	padding: 0;
}

.tape {
	position: absolute;
	left: 0;
	top: -20px;
	height: 45px;
	width: 100%;
	background: url(../img/tape.png) no-repeat center top;
	display: block;
}

.loader {
	margin-left: 5px;
	position: absolute;
}

.error_message {
	color: #F33;
	font-weight: 600;
	margin-bottom: 4px;
}

/*-------- 2.7 Login --------*/
#hero.login {
	height: auto;
	display: block;
	text-transform: none;
	font-size: 12px;
	background: url(../img/bg_login.jpg) no-repeat center center;
	background-size: cover;
}

#login {
	margin: 170px 0 60px;
	color: #333;
	text-align: left;
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
	padding: 30px;
}

#pass-info {
	width: 98.5%;
	margin-bottom: 15px;
	color: #829CBD;
	text-align: center;
	font: 12px/25px Arial, Helvetica, sans-serif;
}

#pass-info.weakpass {
	border: 1px solid #FF9191;
	background: #FFC7C7;
	color: #94546E;
	text-shadow: 1px 1px 1px #FFF;
}

#pass-info.stillweakpass {
	border: 1px solid #FBB;
	background: #FDD;
	color: #945870;
	text-shadow: 1px 1px 1px #FFF;
}

#pass-info.goodpass {
	border: 1px solid #C4EEC8;
	background: #E4FFE4;
	color: #51926E;
	text-shadow: 1px 1px 1px #FFF;
}

#pass-info.strongpass {
	border: 1px solid #6ED66E;
	background: #79F079;
	color: #348F34;
	text-shadow: 1px 1px 1px #FFF;
}

#pass-info.vrystrongpass {
	border: 1px solid #379137;
	background: #48B448;
	color: #CDFFCD;
	text-shadow: 1px 1px 1px #296429;
}

/*-------- 2.8 Tour guide page --------*/
#tour_guide {
	text-align: center;
}

.img-circle.styled {
	border: 5px solid #fff;
}

#filters {
	padding: 20px;
	background-color: #f2f2f2;
	border-bottom: 1px solid #ededed;
	margin: 0 -15px 30px -15px;
}

a.bt_filters {
	background-color: #fff;
	border: 1px solid #ddd;
	padding: 6px 8px;
	color: #888;
	display: inline-block;
}

/*-------- 2.9 Tranfers --------*/
.transfer_container {
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	margin-bottom: 30px;
}

.transfer_container .transfer_title {
	padding: 15px;
	position: relative;
}

.transfer_container .transfer_title .rating {
	font-size: 14px;
	margin-left: -3px;
}

.transfer_container .transfer_title h3 {
	margin: 0;
	font-size: 14px;
	text-transform: uppercase;
}

a.btn_collapse {
	display: inline-block;
	margin-bottom: 10px;
	font-weight: bold;
}

/*-------- 3.0 Hotels --------*/
.score span {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	display: inline-block;
	;
	width: 35px;
	height: 35px;
	border: 2px solid #999;
	line-height: 32px;
	font-size: 13px;
	font-weight: bold;
	color: #999;
	margin-left: 5px;
	text-align: center;
}

.score {
	float: right;
	font-size: 11px;
	margin-top: 5px;
}

#score_detail span {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	display: inline-block;
	;
	width: 45px;
	height: 45px;
	border: 2px solid #555;
	line-height: 42px;
	font-size: 14px;
	font-weight: bold;
	color: #555;
	margin-right: 5px;
	text-align: center;
}

#score_detail {
	font-size: 14px;
	margin-bottom: 15px;
}

#score_detail small {
	color: #999;
}

.hotel_container {
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	margin-bottom: 30px;
}

.hotel_container .hotel_title {
	padding: 15px;
	position: relative;
}

.hotel_container .hotel_title .rating {
	font-size: 14px;
	margin-left: -3px;
}

.hotel_container .hotel_title h3 {
	margin: 0 0 3px 0;
	font-size: 14px;
	text-transform: uppercase;
}

.hotel_container .score span {
	color: #fff;
	margin-right: 5px;
	text-align: center;
	border: 2px solid #fff;
}

.hotel_container .score {
	font-size: 11px;
	margin-top: 5px;
	position: absolute;
	bottom: 30px;
	left: 5px;
	z-index: 9;
}

.hotel_container a .score {
	color: #fff;
}

ul.list_icons {
	margin: 0 0 020px 0;
	padding: 0;
	list-style: none;
}

ul.list_icons li{
	margin-bottom: 10px;
}

ul.list_icons li:last-child{
	margin-bottom: 0;
}

ul.list_icons li i {
	font-size: 16px;
}

.modal-dialog {
	margin-top: 80px;
}

#price_single_main.hotel {
	padding-top: 40px;
}

.hotel_container .short_info.hotel {
	padding-left: 10px;
}

/*-------- 3.1 Wishlist --------*/
.wishlist_close {
	position: absolute;
	top: 10px;
	right: 25px;
	width: 30px;
	height: 30px;
	z-index: 2;
	font-size: 20px;
	line-height: 25px;
	font-size: 18px;
	text-align: center;
	cursor: pointer;
	color: #F66 !important;
}

.wishlist_close:before {
	font-style: normal;
	font-weight: normal;
	font-family: "icon_set_1";
	font-size: 30px;
	content: "\72";
	color: #F66;
	position: absolute;
	left: 0;
	top: 3px;
}

/*-------- 3.2 Map listing --------*/
.marker_info_2 {
	width: 240px;
	height: 340px;
	border-radius: 3px;
	text-align: center;
	background: #FFF;
	background: rgba(255, 255, 255, 0.9);
	position: relative;
	z-index: 9999999;
	font-family: "Montserrat", Arial, sans-serif;
}

.marker_info_2 img {
	display: block;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.marker_info_2 h3 {
	font-size: 18px;
	line-height: 18px;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.marker_info_2 span {
	display: block;
	padding: 0px 20px;
	font-size: 12px;
	line-height: 16px;
	color: #7a7f82;
	margin-bottom: 15px;
}

.marker_info_2:after {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-right-color: rgba(255, 255, 255, 0.9);
	border-width: 10px;
	margin-top: -10px;
}

.full-height {
	height: 100%;
}

.row-height {
	height: 100%;
	min-height: 100%;
}

.map-right {
	height: 100%;
	min-height: 100%;
	padding: 0px;
}

.content-left {
	height: 100%;
	min-height: 100%;
	overflow-y: scroll;
	padding-top: 115px;
}

.view_on_map {
	position: relative;
	cursor: pointer;
	padding: 8px 10px 0 30px;
	margin-top: 10px;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: -8px;
	display: block;
	border-top: 1px solid #ededed;
}

.view_on_map:before {
	font-style: normal;
	font-weight: normal;
	font-family: "fontello";
	font-size: 14px;
	content: "\ecea";
	color: #e04f67;
	position: absolute;
	left: 15px;
	top: 7px;
}

/*============================================================================================*/
/* 3.  COMMON  */
/*============================================================================================*/

/* Magnific popup */
.mfp-bottom-bar {
    padding: 0 10px!important;
}
.mfp-counter {
    right: 10px!important;
}
.mfp-bg {
    opacity: 0.8!important;
}

/* Owl carousel */
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #333!important;
}
.owl-theme .owl-dots .owl-dot {
    outline: none!important;
}
.box_style_1 {
	background: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	border: 1px solid #ddd;
	margin-bottom: 25px;
	padding: 30px;
	position: relative;
	color: #666;
}

.box_style_3 {
	padding: 20px;
	position: relative;
	background: #fff;
	margin-bottom: 30px;
	color: #888;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.box_style_3 h3 {
	font-size: 20px;
}

.box_style_3 i {
	margin: auto;
	margin-bottom: 20px;
	display: block;
	width: 80px;
	height: 80px;
	line-height: 80px;
	text-align: center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px dashed #ccc;
	font-size: 42px;
	color: #6dcff6;
}

.box_style_4,
.box_style_2 {
	background: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	margin-bottom: 25px;
	padding: 20px;
	position: relative;
	text-align: center;
	border: 1px solid #ddd;
}

.box_style_4:after,
.box_style_4:before {
	content: "";
	position: absolute;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	bottom: 100%;
	left: 50%;
	margin-left: -10px;
}

.box_style_4:before {
	border-bottom: 10px solid #ccc;
	margin-bottom: 0;
}

.box_style_4:after {
	border-bottom: 10px solid #fff;
	margin-bottom: -1px;
	z-index: 1;
}

.box_style_2 i,
.box_style_4 i {
	font-size: 52px;
	margin-top: 10px;
	display: inline-block;
}

.box_style_2 a.phone,
.box_style_4 a.phone {
	font-size: 26px;
	display: block;
	margin-bottom: 20px;
	color:#008489;
}

/* Input form style */
.checkbox-inline, .radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
}

.form-control {
	font-size: 13px;
	color: #333;
	height: 40px;
	border-radius: 3px;
}

#booking .form-group {
	position: relative;
}

.expose {
	position: relative;
}

#overlay {
	background: rgba(0, 0, 0, 0.4);
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3;
	bottom: 0;
}

/* Error validate style */
label.error {
	font-size: 11px;
	position: absolute;
	top: -15px;
	right: -30px;
	z-index: 99;
	height: 25px;
	line-height: 25px;
	background-color: #e34f4f;
	color: #fff;
	font-weight: normal;
	padding: 0 6px;
}

label.error:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0 6px 6px 0;
	border-color: transparent #e34f4f;
	display: block;
	width: 0;
	z-index: 1;
	bottom: -6px;
	left: 20%;
}

/* Scroll top button */
#toTop {
	position: fixed;
	right: 0;
	opacity: 0;
	visibility: hidden;
	bottom: 25px;
	margin: 0 25px 0 0;
	z-index: 9999;
	transition: 0.35s;
	transform: scale(0.7);
	width: 46px;
	height: 46px;
	background-color: rgba(0,0,0,.6);
	opacity: 1;
    transition: all 0.3s;
    border-radius: 50%;
    text-align: center;
    font-size: 21px;
	color: #fff;
	cursor: pointer;
}

#toTop.visible {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
}

#toTop:after {
	content: "\e899";
	font-family: "fontello";
	position: relative;
	display: block;
	top: 50%;
	-webkit-transform: translateY(-55%);
	transform: translateY(-55%);
}

/*Background colors*/
.white_bg {
	background-color: #fff
}

/*Spacing*/
.add_bottom_15 {
	margin-bottom: 15px;
}

.add_bottom_30 {
	margin-bottom: 30px;
}

.add_bottom_45 {
	margin-bottom: 45px;
}

.add_bottom_60 {
	margin-bottom: 60px;
}

.nomargin_top {
	margin-top: 0;
}

/*List*/
ul.list_ok {
	list-style: none;
	margin: 0 0 20px 0;
	padding: 0;
	line-height: 1.3;
}

ul.list_ok li {
	position: relative;
	padding-left: 25px;
	margin-bottom: 10px;
}

ul.list_ok li:last-child {
	margin-bottom: 0;
}

ul.list_ok li:before {
	font-style: normal;
	font-weight: bold;
	font-family: "icon_set_1";
	font-size: 14px;
	content: "\6c";
	color: #e04f67;
	position: absolute;
	left: 0;
	top: 0;
}

ul.list_order {
	list-style: none;
	margin: 0 0 30px 0;
	padding: 0;
	line-height: 30px;
	font-size: 14px;
}

ul.list_order li {
	position: relative;
	padding-left: 40px;
	margin-bottom: 10px;
}

ul.list_order li span {
	background-color: #e04f67;
	color: #fff;
	position: absolute;
	left: 0;
	top: 0;
	text-align: center;
	font-size: 18px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/** Pagination **/
.page-link {
    color: #555;
}
.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #555;
    border-color: #555;
}

.page-item .page-link:hover {
    background-color: #555;
    border-color: #555;
	color: #fff;
}

/* Drop down select footer */
.styled-select select {
	background: transparent;
	width: 115%;
	padding: 7px 5px 5px 15px;
	border: 0;
	border-radius: 0;
	height: 37px;
	margin: 0;
	font-weight: 400;
	-moz-appearance: window;
	-webkit-appearance: none;
	cursor: pointer;
	color: #999;
}

.styled-select {
	width: 100%;
	overflow: hidden;
	height: 40px;
	background: #434343 url(../img/down_arrow_select.png) no-repeat right center;
	border: none;
	margin-bottom: 15px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.styled-select select::-ms-expand {
	display: none;
}

.styled-select select:focus {
	outline: none;
}

/* Drop down select filters */
.styled-select-filters select {
	background: transparent;
	width: 115%;
	padding: 7px 5px 5px 10px;
	border: 0;
	border-radius: 0;
	height: 31px;
	margin: 0;
	font-weight: 400;
	-moz-appearance: window;
	-webkit-appearance: none;
	cursor: pointer;
	font-size: 12px;
	color: #888;
}

.styled-select-filters {
	width: 100%;
	overflow: hidden;
	height: 34px;
	background: #fff url(../img/down_arrow_select_filters.png) no-repeat right center;
	border: none;
	margin: 0;
	padding: 0;
	border: 1px solid #ddd;
	display: block;
}

.styled-select-filters select::-ms-expand {
	display: none;
}

.styled-select-filters select:focus {
	outline: none;
}

/* Margin tools */
.margin_60 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.margin_30 {
	margin-top: 30px;
	margin-bottom: 30px;
}

.margin_30 {
	margin-top: 30px;
	margin-bottom: 30px;
}

/* Parallax bg */
.parallax-window {
	min-height: 470px;
	background: transparent;
	position: relative;
}

.parallax-content-1 {
	display: table;
	width: 100%;
	height: 470px;
	background: rgba(0, 0, 0, 0.6);
}

.parallax-content-1 > div {
	display: table-cell;
	padding: 0 15%;
	vertical-align: middle;
	text-align: center;
	color: #fff;
	font-size: 16px;
}

.parallax-content-1 div h1,
.parallax-content-1 div h3 {
	font-size: 48px;
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
}

.parallax-content-1 div h1 {
	margin-bottom: 0;
	padding-top: 40px;
}

.parallax-content-1 div h1:before,
.parallax-content-1 div h1:after {
	display: inline-block;
	width: 50px;
	height: 1px;
	content: '';
	vertical-align: middle;
	background: rgba(255, 255, 255, 0.5);
}

.parallax-content-1 div h1:before {
	margin-right: 10px;
}

.parallax-content-1 div h1:after {
	margin-left: 10px;
}

.parallax-content-1 div a i {
	color: #fff;
	opacity: 0.5;
	font-size: 72px;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.parallax-content-1 div a:hover i {
	opacity: 1;
}

/* Tours boxes*/
.tour_container {
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	margin: 0;
	margin-bottom: 30px;
}

.img_container {
	position: relative;
	overflow: hidden;
}

.tour_container .tour_title {
	padding: 15px;
	position: relative;
}

.tour_container .tour_title .rating {
	font-size: 14px;
	margin-left: -3px;
}

.tour_container .tour_title h3 {
	margin: 0 0 3px 0;
	font-size: 14px;
	text-transform: uppercase;
}

.wishlist {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 40px;
	height: 40px;
	z-index: 2;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	cursor: pointer;
}

.wishlist a {
	color: #888;
	cursor: pointer;
	display: block;
}

.strip_all_tour_list .wishlist a {
	color: #fff;
}

.wishlist:before {
	font-style: normal;
	font-weight: normal;
	font-family: "icon_set_1";
	font-size: 40px;
	content: "\72";
	color: #888;
	position: absolute;
	left: 0;
	top: 4px;
}

.strip_all_tour_list .wishlist {
	position: absolute;
	top: 10px;
	right: 25px;
	width: 30px;
	height: 30px;
	z-index: 2;
	font-size: 20px;
	line-height: 30px;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
	color: #fff !important;
}

.strip_all_tour_list .wishlist:before {
	font-style: normal;
	font-weight: normal;
	font-family: "icon_set_1";
	font-size: 30px;
	content: "\72";
	color: #fff;
	position: absolute;
	left: 0;
	top: 3px;
}

.img_container a:hover img {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.img_container img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-webkit-backface-visibility: hidden;
}

.short_info {
	position: absolute;
	left: 0;
	bottom: 0;
	background: -webkit-linear-gradient(top,transparent,#000);
    background: linear-gradient(to bottom,transparent,#000);
	width: 100%;
	padding: 45px 10px 8px 5px;
	color: #fff;
}

.short_info i {
	font-size: 25px;
	display: inline-block;
	vertical-align: middle;
	font-weight: normal;
	font-style: normal;
	padding: 0;
	margin: 0;
}

.short_info .price {
	float: right;
	font-size: 28px;
	font-weight: bold;
	display: inline-block;
}

.short_info .price sup {
	font-size: 18px;
	position: relative;
	top: -5px;
}

/* Ribbons */
.ribbon {
	position: absolute;
	top: 0;
	left: -1px;
	width: 78px;
	height: 78px;
	z-index: 1;
}

.ribbon.popular {
	background: url(../img/ribbon_popular.png) no-repeat 0 0;
}

.ribbon.top_rated {
	background: url(../img/ribbon_top_rated.png) no-repeat 0 0;
}

/* Tooltips */
.tooltip_styled {
	display: inline;
	position: relative;
	z-index: 999;
}

.tooltip-item {
	cursor: pointer;
	display: inline-block;
}

.tooltip-item::after {
	content: '';
	position: absolute;
	width: 300px;
	height: 20px;
	bottom: 100%;
	left: 50%;
	pointer-events: none;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.tooltip_styled:hover .tooltip-item::after {
	pointer-events: auto;
}

.tooltip-content {
	position: absolute;
	z-index: 9999;
	width: 300px;
	left: 50%;
	margin: 0 0 20px -150px;
	bottom: 100%;
	text-align: left;
	line-height: 20px;
	display: block;
	padding: 15px;
	font-size: 12px;
	color: #fff;
	box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
	background: #333;
	opacity: 0;
	cursor: default;
	pointer-events: none;
}

.tooltip-effect-1 .tooltip-content {
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-2 .tooltip-content {
	-webkit-transform-origin: 50% calc(100% + 10px);
	transform-origin: 50% calc(100% + 10px);
	-webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
	transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-3 .tooltip-content {
	-webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
	transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-4 .tooltip-content {
	-webkit-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transform: scale3d(0.7, 0.3, 1);
	transform: scale3d(0.7, 0.3, 1);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
}

.tooltip_styled:hover .tooltip-content {
	pointer-events: auto;
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
	transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
}

.tooltip_styled.tooltip-effect-2:hover .tooltip-content {
	-webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
	transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
}

.tooltip-content::after {
	content: '';
	top: 100%;
	left: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: transparent;
	border-top-color: #333;
	border-width: 10px;
	margin-left: -10px;
}

.tooltip-content .label {
	margin-left: 10px;
}

.tooltip-content i {
	font-size: 20px !important;
	top: 3px !important;
	left: -3px !important;
	position: relative;
}

.tooltip-content h4 {
	display: block;
	margin: 0 0 10px 0;
	line-height: 14px;
	font-size: 14px;
	color: #fff;
	text-transform: uppercase;
}

.tooltip-content a {
	font-weight: bold;
}

/* Tooltip flip */
.tooltip_flip {
	position: relative;
}

.tooltip_flip::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 20px;
	bottom: 100%;
	pointer-events: none;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.tooltip_flip:hover::after {
	pointer-events: auto;
}

.tooltip-content-flip {
	position: absolute;
	z-index: 9999;
	width: 80px;
	height: 80px;
	left: -40%;
	bottom: 100%;
	text-align: center;
	color: #fff;
	opacity: 0;
	margin-bottom: 7px;
	cursor: default;
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}

.strip_all_tour_list .tooltip-content-flip {
	left: -65%;
}

.tooltip-effect-1 .tooltip-content-flip {
	-webkit-transform: translate3d(0, 10px, 0);
	transform: translate3d(0, 10px, 0);
}

.tooltip_flip:hover .tooltip-content-flip {
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.tooltip-content-flip > span {
	float: left;
	width: 100%;
	padding-left: 5px;
	padding-right: 5px;
	height: 100%;
	position: relative;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.tooltip-back {
	background: #e04f67;
	font-size: 12px;
	line-height: 14px;
	padding-top: 25px;
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
	-webkit-transform: perspective(1000px) rotate3d(0, 1, 0, 90deg);
	transform: perspective(1000px) rotate3d(0, 1, 0, 90deg);
}

.tooltip_flip:hover .tooltip-content-flip > span {
	-webkit-transform: perspective(1000px) rotate3d(0, 1, 0, 0deg);
	transform: perspective(1000px) rotate3d(0, 1, 0, 0deg);
}

.tooltip-content-flip .tooltip-front::after,
.tooltip-content-flip .tooltip-back::after {
	content: '';
	position: absolute;
	top: 100%;
	width: 0;
	height: 0;
}

.tooltip-content-flip .tooltip-front::after {
	right: 0;
	border-top: 10px solid #e64b50;
	border-left: 10px solid transparent;
}

.tooltip-content-flip .tooltip-back::after {
	left: 45%;
	border-top: 10px solid #e64b50;
	border-right: 10px solid transparent;
}

/* Position  breadcrumbs */
#position {
	background-color: #333;
	padding: 10px 0;
	font-size: 11px;
}

#position ul li:first-child {
	padding-left: 20px;
}

#position ul li:first-child:before {
	content: "\eaf4";
	font-style: normal;
	font-weight: normal;
	font-family: "fontello";
	position: absolute;
	left: 0;
	top: 1px;
	color: #fff;
}

#position ul {
	margin: 0;
	padding: 0;
	color: #888;
}

#position ul li a {
	color: #fff;
	opacity: 0.7;
}

#position ul li a:hover {
	color: #fff;
	opacity: 1;
}

#position ul li {
	display: inline-block;
	padding-right: 8px;
	margin-right: 3px;
	position: relative;
}

#position ul li:after {
	content: "\e9ee";
	font-style: normal;
	font-weight: normal;
	font-family: "fontello";
	position: absolute;
	right: 0;
	top: 2px;
}

#position ul li:last-child:after {
	content: '';
}

/*Banners*/
.banner {
	position: relative;
	background: #fff url(../img/banner_bg.png) no-repeat center bottom;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	padding: 30px;
	margin-bottom: 60px;
}

.banner:after,
.banner:before {
	position: absolute;
	content: "";
	z-index: 1;
	height: 18px;
	width: 50%;
	top: 100%;
}

.banner:after {
	left: 0;
	background: url(../img/shadow-bg.png) no-repeat top left;
}

.banner:before {
	right: 0;
	background: url(../img/shadow-bg.png) no-repeat top right;
}

.banner a {
	position: absolute;
	top: 35%;
	right: 30px;
}

.banner h4 {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 32px;
	margin: 0;
	padding: 0;
	color: #e04f67;
	font-family: "Gochi Hand", cursive;
}

.banner h4 span {
	text-transform: none;
	color: #555;
}

.banner p {
	margin: 0;
	padding: 0;
}

.banner.colored {
	background: #e04f67 url(../img/banner_bg_colored.png) no-repeat center bottom;
	color: #fff;
}

.banner.colored h4 {
	color: #fff;
}

.banner.colored h4 span {
	color: #FFC;
}

/*Preloading*/
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
	background-color: #fff;
	z-index: 999999999;
}

.sk-spinner-wave.sk-spinner {
	margin: -15px 0 0 -25px;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 50px;
	height: 30px;
	text-align: center;
	font-size: 10px;
}

.ie8 .sk-spinner-wave.sk-spinner {
	display: none;
}

.sk-spinner-wave div {
	background-color: #ccc;
	height: 100%;
	width: 6px;
	display: inline-block;
	-webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
	animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}

.sk-spinner-wave .sk-rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.sk-spinner-wave .sk-rect3 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

.sk-spinner-wave .sk-rect4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.sk-spinner-wave .sk-rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes sk-waveStretchDelay {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}
}

@keyframes sk-waveStretchDelay {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}
}

.laptop {
	margin: auto;
	margin-top: 20px;
}

/** Collapse updated v.1.8 **/
.card {
	margin-bottom: 10px;
}
.card-header {
	border-radius: 3px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #ddd;
	background-color: #fff;
	border-bottom: none;
	padding-bottom: 10px;
}

.card-header h4{
	font-size: 16px;
	padding-top: 5px;
}

.card-header a {
	display: block;
	color: #777;
}

.card-body {
	padding: 30px;
	line-height: 1.6 !important;
	border-top: 1px solid #ddd;
}

.card-header a .indicator {
	color: #e04f67;
}

.card-header a:hover .indicator {
	color: #333;
}

/** Blockquote **/
blockquote.styled {
	font-family: "Gochi Hand", cursive;
	line-height: 1.42857143;
	font-size: 16px;
}

/** tabs **/
.tab-content {
	padding: 30px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-top: none;
	margin-bottom: 25px;
	-webkit-border-bottom-right-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-moz-border-radius-bottomright: 3px;
	-moz-border-radius-bottomleft: 3px;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

/** Img frame in general page **/
.img-fluid.styled {
	margin-top: 20px;
	border: 5px solid#fff;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

/**General icons block **/
.general_icons ul {
	list-style: none;
	padding: 0;
	margin: 0;
	margin: 30px 0 0 0;
}

.general_icons ul li {
	display: inline-block;
	text-align: center;
	padding: 0 20px 20px 0;
	white-space: nowrap;
}

.general_icons ul li:last-child {
	padding-right: 0;
}

.general_icons ul li i {
	font-size: 36px;
	display: block;
	margin-bottom: 10px;
}

/*============================================================================================*/
/* 4.  UPDATES  */
/*============================================================================================*/

/* 4.1 Home 7: search function */
#search_container {
	position: relative;
	height: 700px;
	background: #4d536d url(../img/slide_hero.jpg) no-repeat center top;
	background-size: cover;
	color: #fff;
	width: 100%;
	display: table;
	z-index: 99;
}

#search {
	display: table-cell;
	vertical-align: middle;
	padding: 0 20%;
	padding-top: 100px;
	background: rgba(0, 0, 0, 0.6);
}

#search .nav-tabs {
	border-radius: 0;
	font-size: 12px;
	border: 0;
	border-bottom: 1px solid transparent;
	margin: 0 0 6px 10px;
	text-transform: uppercase;
}

#search .nav-tabs > li > a {
	border-radius: 0;
	border: 0;
	color: #fff !important;
	padding: 10px 15px;
	margin-right: 3px;
}

#search .tab-content {
	border-radius: 0;
	border: 0;
	color: #333;
}

#search .nav-tabs > li > a {
	background: rgba(0, 0, 0, 0.40);
}

#search .nav-tabs > li > a.active ,
#search .nav-tabs > li > a:hover .active,
#search .nav-tabs > li > a:focus .active {
	color: #fff;
	background-color: #e14d67;
}

.tab-pane h3 {
	color: #999;
	margin: 0 0 20px 0;
}

.tab-pane h3 {
	color: #999;
	margin: 0 0 20px 0;
}

.dd-select {
	border-radius: 3px !important;
	border: solid 1px #ccc;
	position: relative;
	cursor: pointer;
	height: 40px;
	font-weight: normal !important;
}

.dd-desc {
	color: #aaa;
	display: block;
	overflow: hidden;
	font-weight: normal;
}

.dd-selected {
	overflow: hidden;
	display: block;
	padding: 0 0 0 10px !important;
	font-weight: normal !important;
	color: #444 !important;
}

.dd-pointer {
	width: 0;
	height: 0;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -3px;
}

.dd-pointer-down {
	border: solid 5px transparent;
	border-top: solid 5px #999 !important;
}

.dd-pointer-up {
	border: solid 5px transparent !important;
	border-bottom: solid 5px #000 !important;
	margin-top: -8px;
}

.dd-option {
	padding: 10px;
	display: block;
	border-bottom: solid 1px #ddd;
	overflow: hidden;
	text-decoration: none;
	color: #333;
	cursor: pointer;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
}

.dd-options > li:last-child > .dd-option {
	border-bottom: none;
}

.dd-options li .dd-option {
	height: 45px;
	line-height: 25px !important;
}

.dd-option:hover {
	background: #f9f9f9 !important;
	color: #000;
}

.dd-option-selected {
	background: #f9f9f9 !important;
	color: #444 !important;
}

.dd-option-image,
.dd-selected-image {
	vertical-align: middle;
	float: left;
	margin-right: 10px !important;
	max-width: 64px;
}

.dd-image-right {
	float: right;
	margin-right: 15px;
	margin-left: 5px;
}

.dd-container {
	position: relative;
}

label.dd-selected-text {
	font-weight: normal !important;
}

.dd-option-text {
	line-height: 14px !important;
}

.dd-selected-image {
	margin-top: 7px;
}

.dd-select label,
.dd-options label {
	font-weight: normal !important;
	cursor: pointer !important;
}

.radio_fix {
	float: left;
	margin-top: 35px;
}

/*-------- 4.1 Faq --------*/
#faq h2 {
	margin: 0 0 20px 0;
	font-size: 24px;
}

/*-------- 4.2 Pricing tables --------*/
.plans {
	margin: 0px auto 50px;
	zoom: 1;
}

.plans:before,
.plans:after {
	content: '';
	display: table;
}

.plans:after {
	clear: both;
}

.plan {
	margin: 10px 0;
	padding: 20px;
	text-align: center;
	background: #fafafa;
	background-clip: padding-box;
	border: solid #dddddd;
	min-height: 435px;
	border-width: 1px 0 1px 1px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #fff;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.col-md-4.plan:first-child {
	margin-right: -30px;
	margin-left: 30px;
}

.plan:last-child {
	border-width: 1px;
}

.plan-title {
	position: relative;
	margin: -20px -10px 20px;
	padding: 20px;
	line-height: 1;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: bold;
	border-bottom: 1px dotted #ccc;
}

.plan-title:before {
	content: '';
	position: absolute;
	bottom: -1px;
	left: 0;
	right: 0;
	height: 1px;
}

.plan-price {
	margin: 0 auto 20px;
	width: 110px;
	height: 110px;
	line-height: 110px;
	font-size: 30px;
	font-weight: bold;
	color: white;
	background: #4b4b4b;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	display: inline-block;
	text-align: center;
	font-family: "Helvetica Neue", Arial;
}

.plan-price > span {
	font-size: 12px;
	font-weight: normal;
	color: rgba(255, 255, 255, 0.9);
}

.plan-features {
	margin-bottom: 20px;
	line-height: 2;
	font-size: 12px;
	text-align: center;
}

ul.plan-features {
	padding: 0;
	margin: 0 0 30px 0;
}

.plan-features li {
	list-style: none;
}

.plan-tall {
	margin: 0;
	background: #fff;
	border-width: 1px;
	border-radius: 4px;
	margin-right: -30px;
	position: relative;
	z-index: 100;
	border: solid #83c99f;
	position: relative;
}

.ribbon_table {
	width: 99px;
	height: 97px;
	position: absolute;
	left: -7px;
	top: -7px;
	display: block;
	background: url(../img/ribbon.png) no-repeat 0 0;
	z-index: 101;
}

.plan-tall > .plan-title {
	font-size: 18px;
}

.plan-tall > .plan-price {
	margin: 0 auto 20px;
	height: 130px;
	width: 130px;
	line-height: 130px;
	font-size: 30px;
	font-weight: bold;
	color: white;
	background: #f26d7d;
	border-radius: 130px;
	-webkit-border-radius: 130px;
	-moz-border-radius: 130px;
	display: inline-block;
	text-align: center;
}

.plan-tall > .plan-features {
	font-size: 14px;
}

.plan-tall > .plan-button {
	padding: 0 16px;
	line-height: 32px;
}

.plan-tall + .plan {
	border-left: 0;
}

/* Pricing tables style 2 */
#pricing_2 {
	margin-top: 20px;
}

.ribbon_2 {
	width: 99px;
	height: 97px;
	position: absolute;
	left: -5px;
	top: -5px;
	display: block;
	background: url(../img/ribbon.png) no-repeat 0 0;
	z-index: 101;
}

.pricing-table {
	text-align: center;
	font-weight: 400;
	margin-bottom: 30px;
	background: #fff;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	position: relative;
	padding-bottom: 5px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.pricing-table:hover {
	-moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
	-webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
}

.pricing-table.green {
	text-align: center;
	font-weight: 400;
}

.pricing-table.black {
	text-align: center;
	font-weight: 400;
}

.pricing-table-sign-up {
	border-top: 1px solid #ededed;
	padding: 10px 10px 5px 10px;
	text-align: center;
	margin-top: 30px;
}

.pricing-table-features p {
	padding: 10px 20px;
	text-align: center;
	margin: 10px 0;
}

.pricing-table-features p:nth-child(2n) {
	background: #f8f8f8;
	padding: 10px 0;
}

.pricing-table-features,
.pricing-table-space {
	background: #fff;
}

.pricing-table p {
	padding: 5px;
	margin-top: 5px;
	font-size: 110%;
	font-weight: 400;
	background: #fff;
}

.pricing-table p strong {
	font-weight: 600;
}

.pricing-table .pricing-table-header {
	color: #fff;
	padding: 0px;
}

.pricing-table-header .heading {
	display: inline-block;
	width: 100%;
	padding: 15px 0px;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 18px;
}

.pricing-table.green .heading {
	background: #83c99f;
}

.pricing-table.black .heading {
	background: #333333;
}

.pricing-table .pricing-table-header .price-value {
	background: #fff;
}

.pricing-table.green .pricing-table-header .price-value {
	background: #fff;
}

.pricing-table.black .pricing-table-header .price-value {
	background: #fff;
}

.pricing-table-header .price-value {
	display: inline-block;
	width: 100%;
	padding: 10px 0px;
	background: #1ABC9C;
	font-family: "Helvetica Neue", Arial;
	font-weight: bold;
	color: #555;
	border-bottom: 1px solid #ededed;
	margin-bottom: 15px;
}

.pricing-table-header .price-value span {
	font-weight: 800;
	font-size: 36px;
	line-height: 36px;
}

.pricing-table-header .price-value span.mo {
	font-size: 22px;
	font-weight: 400;
}

.question_box h3 {
	font-size: 18px;
	text-align: left;
	line-height: 22px;
	margin-bottom: 10px;
}

.question_box {
	padding-left: 40px;
	position: relative;
	margin-bottom: 30px;
}

.question_box:before {
	content: "\ec7e";
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	font-size: 30px;
	position: absolute;
	color: #999;
	top: 5px;
	left: 0;
}

/*-------- 4.4 Common --------*/
.badge_save {
	position: absolute;
	top: 0;
	right: 0;
	width: 65px;
	height: 77px;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	background: url(../img/badge_save.png);
	font-size: 11px;
	line-height: 12px;
	padding-top: 32px;
}

.badge_save strong {
	display: block;
	font-size: 14px;
	font-weight: bold;
}

.input-group input {
	height: 34px;
	border: none;
}
.input-group input:focus {
	border: none;
	box-shadow: none;
}

/* Promo_full */
.promo_full {
	height: auto;
	background: url(../img/home_bg_2.jpg) no-repeat center center;
	background-attachment: fixed;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	position: relative;
}

.promo_full_wp {
	display: table;
	width: 100%;
	height: auto;
}

.promo_full_wp > div {
	display: table-cell;
	padding: 10% 15%;
	vertical-align: middle;
	text-align: center;
	color: #fff;
	font-size: 16px;
	background-color: rgba(0, 0, 0, 0.6);
}

.promo_full_wp div h3 {
	font-size: 48px;
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
}

.promo_full_wp div p {
	font-size: 23px;
	font-weight: 300;
	line-height: 1.4;
}

@media (max-width: 575px) {
	.promo_full_wp div h3 {
		font-size: 36px;
	}
	.promo_full_wp > div {
		padding: 10% 30px;
	}
  .promo_full_wp div p {
	font-size: 18px;
	}
}

.promo_full_wp div a i {
	color: #fff;
	opacity: 0.5;
	font-size: 72px;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.promo_full_wp div a:hover i {
	opacity: 1;
}

/* Map filter */
#map_filter {
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 2;
	width: 100%;
}

#map_filter ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
}

#map_filter ul li {
	display: inline-block;
	text-align: center;
}

#map_filter ul li a {
	display: inline-block;
	padding: 15px;
	color: #444;
	min-width: 120px;
	margin: 0 -3px;
}

#map_filter ul li a:hover,
#map_filter ul li a.active {
	background-color: #fff;
	color: #e04f67;
}

#map_filter ul li a i {
	display: block;
	font-size: 36px;
	margin: 5px 0;
}

/* Map contact */
#map_contact.contact_2 {
	margin-top: 98px;
}

/* Sticky sidebar */
.theiaStickySidebar{
	z-index:4!important;
}
/*============================================================================================*/
/* 5.  UPDATES  */
/*============================================================================================*/

/*-------- 5.1 Ribbons css --------*/
.ribbon_3 {
	position: absolute;
	left: 10px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}

.ribbon_3 span {
	font-size: 10px;
	font-weight: bold;
	color: #FFF;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 100px;
	display: block;
	background: #79A70A;
	background: linear-gradient(#9BC90D 0%, #79A70A 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
	position: absolute;
	top: 19px;
	left: -21px;
}

.ribbon_3 span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #638908;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #638908;
}

.ribbon_3 span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #638908;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #638908;
}

.ribbon_3.popular span {
	background: linear-gradient(#f14f3f 0%, #cb4335 100%);
}

.ribbon_3.popular span::before {
	border-left: 3px solid #a3362a;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #a3362a;
}

.ribbon_3.popular span::after {
	border-left: 3px solid transparent;
	border-right: 3px solid #a3362a;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #a3362a;
}

/*-------- 5.2 Footer styles --------*/
/* Footer 3 */
footer#pattern_2 {
	background: #222 url(../img/pattern_3.png) repeat 0 0;
}

footer #logo {
	margin-top: 20px;
}

/* Footer 4 */
#newsletter_container {
	background-color: #f8f8f8;
	border-top: 1px solid #ededed;
}

#newsletter_container h3 {
	font-size: 32px;
	font-weight: 300;
	margin: 0 0 20px 0;
}

#newsletter_container input.form-control#email_newsletter_2 {
	margin-bottom: 0;
	background-color: #fff;
	border: none;
	border: 2px solid #ededed;
	height: 45px;
	font-size: 15px;
	padding-left: 20px;
	width: 380px;
	margin-right: 5px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	display: inline-block;
}

#newsletter_container input.form-control#email_newsletter_2:focus {
	border-color: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #444;
}

#newsletter_container #submit-newsletter_2.btn_1 {
	padding: 12px 35px;
	display: inline-block;
}

/* Footer 5 */
ul.tweet_list li{
	padding-left: 30px;
	background: url(../img/twitter_icon.svg) no-repeat 0 5px;
}
.tweets-next,
.tweets-prev {
	display: inline-block;
	font-size: 28px;
	color: #FFF;
	margin-top: 5px;
	width: 28px;
	padding: 0;
	text-align: left;
}

.tweets-prev {
	margin-right: 5px;
}

.tweet_avatar {
	float: left;
}

.tweet_time a{
	display: block;
	margin-bottom: 5px;
	color: #fdf7ac;
}

a.bx-next, a.bx-prev{
	color: #fff;
}

a.bx-next:hover, a.bx-prev:hover{
	color: #fdf7ac;
}

.tweet_text a{
	text-decoration: underline;
}

.tweet-control {
	margin-left:15px;
}

/* Footer 6 */
footer#pattern_3 {
	background: #222 url(../img/pattern_4.png) repeat 0 0;
}
footer .post{
	position:relative;
	min-height:62px;
	padding-left:80px;
	padding-top:5px;
	margin-bottom:10px;	
}

footer .post:last-child{
	margin-bottom:5px;	
}

footer .post figure{
	position:absolute;
	left:0px;
	top:0px;
	width:62px;
	height:62px;
}

footer .post h4{
	font-size:13px;
	color:#ffffff;
	margin-bottom:6px;	
}

footer.post i{
	font-size:14px;
	padding-right:10px; 
}

/* Footer 7 */
footer#pattern_4 {
	background: #2a2a2a url(../img/pattern_5.png) repeat 0 0;
}

footer .gallery-image {
  overflow: hidden;
}

footer .gallery-image a {
  max-width: 33.33%;
  padding-right: 10px;
  padding-bottom: 10px;
  float: left;
}

footer .gallery-image img {
  width: 100%;
  transition: 0.3s ease-in-out;
}

/*-------- 5.3 Header versions --------*/
/* Header 4*/
#opening{
	display:inline-block;
	margin-left:15px;
}

#social_top a i{
	font-size:13px;
	color:#fff;
}

#social_top a i:hover{
	color:#e05068;
}

/* Header 5*/
#lang_top i{
		font-size:14px;
}

.dropdown-menu#log_out{
	text-align:center;
}

.dropdown-menu#log_out p{
	color:#999;
}

/* Header 6*/
 .dropdown-mini .dropdown-menu {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  float: right;
  left: auto;
  text-align:left;
  min-width: 0;
  padding: 0;
  right: 0;
  min-width:100px;
  width: auto;
  top:100%;
  margin: 7px 0 0 -50px;
  font-size:12px;
  font-weight: normal;
    text-shadow:none;
	text-transform:none !important;
	border-top: 2px solid #e04f67;
}

 .dropdown-mini .dropdown-menu:before {
	bottom: 100%;
	right: 10%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: #e04f67;
	border-width: 7px;
	margin-left: -7px;
}
 .dropdown-mini .dropdown-menu ul#lang_menu{
	 padding:0;
	 margin:0;
	 text-align:left;
}

 .dropdown-mini .dropdown-menu ul#lang_menu li{
	padding:0;
	margin:0;
	width:100%;
}

 .dropdown-mini .dropdown-menu ul#lang_menu li a{
	 color:#555 !important;
	 text-align:left;
	 border-bottom:1px solid #ededed;
	 padding:8px;
	 display:block;
}

 .dropdown-mini .dropdown-menu ul#lang_menu li a:hover{
	 background-color:#f9f9f9;
	 color:#e04f67 !important;
	 padding-left:15px;
}

a#lang_link, a#currency_link{
	position:relative;
	display:inline-block;
	padding-left:20px;
}

#lang_link:before, #currency_link:before{
	font-style: normal;
 	font-weight: normal;
 	font-family: "fontello";
    font-size:14px;
	position:absolute;
	left:0;
	top:0;
}

#lang_link:before{content: "\ea26";}

#currency_link:before{content: "\e8b8";}

/*-------- 5.4 Gallery overlay --------*/
.img_wrapper_gallery {
	margin: 0;
	padding:0;
	margin-top: 20px;
	border: 4px solid#fff;
	background-color:#000;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.img_container_gallery a:hover img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	opacity: 0.6;
}

.img_container_gallery img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-moz-transition: all 0.4s;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	opacity: 1;
	-webkit-backface-visibility: hidden;
}

.img_container_gallery {
	position: relative;
	overflow: hidden;
	z-index: 0;
}

.img_container_gallery i{
	position: absolute;
	top: 50%;
	z-index: 10;
	display: none;
	color: #fff;
	font-size: 30px;
	left: 50%;
	margin-top:-15px;
	margin-left: -15px;
	display:none;
}

.img_container_gallery a:hover i{
	display: block;
}

/*-------- 5.5 Map infobox + map listing --------*/
.infoBox {padding-right:25px !important;}

.map-right #map{
	height: 100%;
	width:100%;
}

a.btn_infobox_detail:before, .btn_infobox_get_directions:before,a.btn_infobox_phone:before{
	 font-style: normal;
	 font-weight: normal;
	 font-family: "Fontello";
	 font-size:20px;
	 cursor:pointer;
}

.btn_infobox_get_directions, a.btn_infobox_phone{
	border:none;
	display:inline-block;
	color:#e04f67;
	background:none;
	cursor:pointer;
	margin: 0 5px;
	font-size:13px;
	position:relative;
	padding-left:18px;
	outline:none;
}

.btn_infobox_get_directions:hover, a.btn_infobox_phone:hover{color:#333;}

.btn_infobox_get_directions:before{
  font-size:14px;
  content: "\ecea";
  position:absolute;
  left:0;
  top:2px;
}

a.btn_infobox_phone:before{
  font-size:13px;
  content: "\eed5";
  position:absolute;
  left:0;
  top:0;
}

a.btn_infobox, .btn_infobox{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background: #51bce6;
	cursor: pointer;
	padding: 5px 15px;
	display: inline-block;
	outline: none;
	font-size:11px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
}

a.btn_infobox:hover, .btn_infobox:hover{
	background: #333;
}

.marker_tools{
	margin:10px 0 15px 0;
}
/*============================================================================================*/
/* 6.  UPDATES  */
/*============================================================================================*/

/*-------- 6.1 Footer fixed --------*/
main{
	background-color: #f9f9f9;
	z-index:2;
	position:relative;
}
.parallax-content-1 {
	z-index: 3;
	position: relative;
}

/*-------- 6.2 Notify --------*/
[data-notify="progressbar"] {
	margin-bottom: 0px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 5px;
}
.customized_notify.alert-info{
	text-align:left;
	background-color:#fff;
	width: 360px;
	line-height: 1.4;
	border:0;
	color:#777;
	padding:20px;
	-webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
}
.customized_notify.alert-info h4{
	color:#333;
	font-size:14px;
	margin: 5px 0 0 -4px !important;
	text-transform:uppercase;
	font-weight:700;
	display: inline-block;
}
.customized_notify i span{
	font-size:55px;
	margin-bottom:0;
	color:#6C6;
	display:inline-block;
}
.customized_notify i span img{
	border:5px solid #ededed;
	width:80px;
	height:auto;
	margin-bottom: 10px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}
.customized_notify {
	padding-left: 80px!important;
	min-height: 85px!important;
}
.customized_notify p{
	margin: 0!important;
}

.customized_notify a{
	color: #777;
}

.customized_notify figure{
	width: 55px;
	height: 55px;
	overflow: hidden;
	border-radius: 50%;
	-webkit-border-radius: 50%;
 	 -moz-border-radius: 50%;
 	 -ms-border-radius: 50%;
 	 position: absolute;
 	 left: 15px;
 	 top: 15px;
 	 border:2px solid #ededed;
}
.customized_notify figure img{
	width: 55px;
	height: auto;

}

.customized_notify .close {
    font-weight: 500;
    opacity: .2;
}
@media (max-width: 767px) {
	.customized_notify {
		display: none!important;
	}
}
/*-------- 6.3 Search modal --------*/
.search-overlay-menu {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	-webkit-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
	-moz-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
	-ms-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
	-o-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
	transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
	text-align: center;
}
.search-overlay-menu.open {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
	-moz-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
	-ms-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
	-o-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
	transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
}
.search-overlay-menu form {
	height: 70px;
	position: absolute;
	top: 50%;
	left: 0;
	margin: -35px auto 0;
	width: 60%;
	left: 20%;
	transform: translateY(20px);
	-moz-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-o-transform: translateY(20px);
	-webkit-transform: translateY(20px);
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
}
.search-overlay-menu.open form {
	transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	-webkit-transform: translateY(0px);
}
.search-overlay-menu input[type="search"] {
	height: 100%;
	width: 100%;
	color: #fff;
	background: transparent;
	border-top: 0px solid transparent;
	border-bottom: 2px solid #909090 !important;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 32px;
	font-weight: 400;
	outline: none;
	padding: 10px 0 10px 0;
}
.search-overlay-menu form button {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: medium none;
	font-size: 32px;
	position: absolute;
	right: 0;
	top: 20px;
	color: #c6c6c6;
	outline: none;
}
.search-overlay-menu form button:hover{
	color: #e04f67;
}
.search-overlay-menu .search-overlay-close {
	position: absolute;
	top: 20px;
	right: 20px;
	opacity: 1;
	font-size: 25px;
	color: #c6c6c6;
}
.search-overlay-menu .search-overlay-close:hover {
	color: #e04f67;
	cursor: pointer;
}
body.has-fullscreen-modal {
 	position: fixed;
	width: 100%;
}

/*-------- 6.4 Header 7 --------*/
nav.full-horizontal{
	margin-top:10px;
}
header.sticky nav.full-horizontal{
	margin-top:0;
}

/*============================================================================================*/
/* 7. UPDATES */
/*============================================================================================*/

/*-------- 7.1 Date dropper/Time dropper --------*/
.form-control:disabled.booking_date,
.form-control[readonly].booking_date,
.form-control:disabled.booking_time,
.form-control[readonly].booking_time {
  background-color: #fff;
  opacity: 1;
}
div.datedropper, .td-wrap {
  font-family:"Montserrat",Arial, sans-serif!important;
}
div.datedropper .picker .pick-lg ul.pick-lg-b li.pick-v:hover {
  text-decoration: none !important;
}
div.datedropper.primary:before,
div.datedropper.primary .pick-submit,
div.datedropper.primary .pick-lg-b .pick-sl:before,
div.datedropper.primary .pick-m,
div.datedropper.primary .pick-lg-h {
  background-color: #e04f67 !important;
}
div.datedropper.primary .pick-y.pick-jump,
div.datedropper.primary .pick li span,
div.datedropper.primary .pick-lg-b .pick-wke,
div.datedropper.primary .pick-btn {
  color: #e04f67;
}


/*-------- 7.2 Tabs home--------*/
.tabs {
	position: relative;
	width: 100%;
	overflow: visible;
	margin-bottom:45px;
}

/* Nav */
.tabs nav {
	text-align: center;
	overflow:hidden;
}

.tabs nav ul {
	padding: 0;
	margin: 0;
	list-style: none;
	display: inline-block;
}

.tabs nav ul li {
	border: 1px solid #e2e2e2;
	border-bottom: none;
	margin: 0 0.25em;
	display: block;
	float: left;
	position: relative;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.tabs nav li.tab-current {
	border: 1px solid #e2e2e2;
	border-bottom: none;
	z-index: 100;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.tabs nav li.tab-current:before,
.tabs nav li.tab-current:after {
	content: '';
	position: absolute;
	height: 1px;
	right: 100%;
	bottom: 0;
	width: 1000px;
	background: #e2e2e2;
}

.tabs nav li.tab-current:after {
	right: auto;
	left: 100%;
	width: 4000px;
}

.tabs nav a {
	color: #555;
	display: block;
	font-size: 18px;
	line-height: 2.5;
	padding: 0 1.25em;
	white-space: nowrap;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	
}

.tabs nav a:hover {
	color: #111;
}

/* Icons */
.tabs nav a:before {
	display: inline-block;
	vertical-align: middle;
	text-transform: none;
	font-weight: normal;
	font-variant: normal;
	font-family: 'icon_set_1';
	line-height: 1;
	speak: none;
	font-size:24px;
	-webkit-font-smoothing: antialiased;
	margin: -0.25em 0.4em 0 0;
	position:relative;
	top:3px;

}

.icon-hotels:before {
	content: "\26";
}

.icon-tours:before {
	content: "\3e";
}

.icon-restaurants:before {
	content: "\5a";
}


/* Content */
.content section {
	padding: 40px 0 0 0;
	display: none;
	max-width: 1230px;
	margin: 0 auto;
}

.content section:before,
.content section:after {
	content: '';
	display: table;
}

.content section:after {
	clear: both;
}

.no-js .content section {
	display: block;
	padding-bottom: 2em;
	border-bottom: 1px solid #e2e2e2;
}

.content section.content-current {
	display: block;
}

.mediabox {
	float: left;
	width: 35%;
	padding: 0 15px;
}
.list_tours_tabs h2{
	margin:0;
	padding:0;
	font-size:20px;
	color:#999;
	margin-bottom:15px;
}
.list_tours_tabs  ul{
	list-style:none;
	margin:0 0 0 0;
	width:100%;
	padding:0;
}
.list_tours_tabs  ul li{
	position:relative;
	padding:15px 5px 10px 70px;
	min-height:65px;
	width:100%;
	border-bottom:1px solid #ededed;
	display:table;
}
.list_tours_tabs  ul li a:hover {
	color:#e04f67;
}
.list_tours_tabs  ul li a small{
	color:#777;
	margin:0;
	padding:0;
	font-size:12px;
}
.list_tours_tabs  ul li:last-child{
	border-bottom:none;
	padding-left:15px;
	padding-top:10px;
}
.list_tours_tabs  ul li div{
	display: table-cell;
	vertical-align:middle;
}
.list_tours_tabs  ul li figure{
	width:45px;
	height:45px;
	overflow:hidden;
	position:absolute;
	left:10px;
	top:10px;
}
.list_tours_tabs  ul li figure img{
	width:45px;
	height:auto;
}
.list_tours_tabs  ul li h3{
	font-size:15px;
	margin:0;
	padding:0;
}
.list_tours_tabs  ul li:hover h3{
	color:#e04f67;
}
.list_tours_tabs  ul li:hover{
	background-color:#f8f8f8;
}
.list_hotels_tabs h3{
	font-size:18px;
	margin-top:0;
}

/* Media queries */
@media screen and (max-width: 52.375em) {
	.tabs nav a span {
		display: none;
	}

	.tabs nav a:before {
		margin-right: 0;
	}

	.mediabox {
		float: none;
		width: auto;
		padding: 0 0 35px 0;
		font-size: 90%;
	}

	.mediabox img {
		float: left;
		margin: 0 25px 10px 0;
		max-width: 40%;
	}

	.mediabox h3 {
		margin-top: 0;
	}

	.mediabox p {
		margin-left: 40%;
		margin-left: calc(40% + 25px);
	}

	.mediabox:before,
	.mediabox:after {
		content: '';
		display: table;
	}

	.mediabox:after {
		clear: both;
	}
}

@media screen and (max-width: 32em) {
	
	.mediabox {
		text-align: center;
	}

	.mediabox img {
		float: none;
		margin: 0 auto;
		max-width: 100%;
	}

	.mediabox h3 {
		margin: 1.25em 0 1em;
	}

	.mediabox p {
		margin: 0;
	}
}

/*-------- 7.3 Morphext --------*/
.morphext > .animated {
    display: inline-block;
}

/*-------- 7.4 Cookie-bar --------*/
#cookie-bar {background:rgba(0, 0, 0, 0.8); height:auto; line-height:24px; color:#eeeeee; text-align:center; padding:5px 0;}
#cookie-bar.fixed {position:fixed; top:0; left:0; width:100%; z-index:999999;}
#cookie-bar.fixed.bottom {bottom:0; top:auto;}
#cookie-bar p {margin:0; padding:0;}
#cookie-bar a {color:#ffffff; display:inline-block; border-radius:3px; text-decoration:none; padding:0 10px; margin-left:8px;}
#cookie-bar .cb-enable {background: #6C9}
#cookie-bar .cb-enable:hover {background: #6C6}
#cookie-bar .cb-disable {background:#990000;}
#cookie-bar .cb-disable:hover {background:#bb0000;}
#cookie-bar .cb-policy {background:#51bce6;}
#cookie-bar .cb-policy:hover {background: #09C;}

/* Mobile Landscape */
@media (max-width: 480px) {
#cookie-bar a { display:block; max-width:150px; margin:auto; margin-bottom:5px;}

}

/*-------- 7.5 Sing In Modal --------*/
#sign-in-dialog {
  background: #fff;
  padding: 30px;
  padding-top: 0;
  text-align: left;
  max-width: 400px;
  margin: 40px auto;
  position: relative;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/* Popup close button*/
#sign-in-dialog .mfp-close,
#small-dialog .mfp-close {
  color: #666;
  background-color: #e4e4e4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
#sign-in-dialog .mfp-close:hover,
#small-dialog .mfp-close:hover {
  color: #fff;
  background-color: #66676b;
}

#sign-in-dialog .mfp-close:before {
  font-size: 24px;
  font-family: 'ElegantIcons';
  content: "\4d";
}

.small-dialog-header {
  font-size: 18px;
  width: calc(100% + 60px);
  position: relative;
  left: -30px;
  top: 0;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  display: inline-block;
  background-color: #f6f6f6;
  padding: 15px 20px;
  margin-bottom: 30px;
}
.small-dialog-header h3 {
  padding: 0;
  margin: 0;
  font-size: 21px;
  font-weight: 400;
}

#sign-in-dialog .mfp-close:hover,
#small-dialog .mfp-close:hover {
  color: #fff;
  background-color: #66676b;
}

#sign-in-dialog .form-group {
  position: relative;
}
#sign-in-dialog .form-group input.form-control {
  padding-left: 40px;
}
#sign-in-dialog .form-group i {
  font-size: 21px;
  font-size: 1.3125rem;
  position: absolute;
  left: 12px;
  top: 35px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important;
}
#sign-in-dialog .form-group i.icon_lock_alt {
  top: 37px;
}

.checkboxes {
  position: relative;
  color: #999;
}

.my-toggle {
  background: transparent;
  border: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #888;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  margin-right: 5px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
}

.my-toggle:hover,
.my-toggle:focus {
  background-color: #eee;
  color: #555;
  outline: transparent;
}

#forgot_pw {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  min-height: 400px;
  display: none;
}

.sign-in-wrapper {
  position: relative;
  height: 100%;
}

a.social_bt {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
  min-width: 200px;
  margin-bottom: 15px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
a.social_bt:hover {
  -webkit-filter: brightness(115%);
  filter: brightness(115%);
}
a.social_bt.facebook:before, a.social_bt.google:before, a.social_bt.linkedin:before {
  font-family: 'ElegantIcons';
  position: absolute;
  left: 12px;
  top: 10px;
  font-size: 1rem;
  color: #fff;
}
a.social_bt.facebook {
  background-color: #3B5998;
}
a.social_bt.facebook:before {
  content: "\e093";
}
a.social_bt.google {
  background-color: #DC4E41;
}
a.social_bt.google:before {
  content: "\e096";
  top: 12px;
}
a.social_bt.linkedin {
  background-color: #0077B5;
}
a.social_bt.linkedin:before {
  content: "\e09d";
}
.divider {
  text-align: center;
  height: 1px;
  margin: 30px 0 20px 0;
  background-color: #e1e8ed;
}
.divider span {
  position: relative;
  top: -20px;
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  font-style: italic;
}

/*-------- 7.6 Styles Switcher --------*/
	#style-switcher h3 {
		color: #fff;
		font-size: 13px;
		margin: 10px 0;
		font-weight: 500;
		text-transform: none;
		letter-spacing: 0;
	}
	
	#style-switcher {
		background: #fff;
		width: 205px;
		position: fixed;
		top: 205px;
		z-index: 999;
		left: -205px;
		border-radius: 0 0 4px 0;
		-webkit-box-shadow: 0 6px 15px rgba(0,0,0,.175);
    	box-shadow: 0 6px 15px rgba(0,0,0,.175);
	}
	
	#style-switcher ul {
		list-style: none;
		padding: 0;
		margin-bottom: 22px;
	}
	
	#style-switcher div {
		padding: 6px 18px;
	}
	
	#style-switcher h2 {
		background: #f8f8f8;
		color: #333;
		font-size: 14px;
		line-height: 46px;
		padding: 0 0 0 20px;
		margin-top: 0;
		font-weight: 500;
	}
	
	#style-switcher h2 a {
		background-color: #f8f8f8;
		display: block;
		height: 46px;
		position: absolute;
		right: -46px;
		top: 0;
		width: 46px;
		border-radius: 0 4px 4px 0;
		font-size: 22px;
		color: #fff;
		text-align: center;
	}
	
	#style-switcher h2 a i {
		position: relative;
		top: 2px;
		color: #333;
	}
	
	.colors {
		list-style: none;
		margin: 0 0px 10px 0;
		overflow: hidden;
	}
	
	.colors li {
		float: left;
		margin: 4px 2px 0 2px;
	}
	
	.colors li a {
		display: block;
		width: 17px;
		height: 17px;
		cursor: pointer;
	}
	
	.default {
		background: #e04f67;
	}
	
	.aqua {
		background: #1cbbb4;
	}
	
	.green_switcher {
		background: #82ca9c;
	}
	
	.orange {
		background: #f7941d;
	}

	.blue {
		background: #0072bc;
	}
	
	.colors li a.active {
		box-shadow: 0 0 0 2px #fff inset
	}
	
	.colors li a {
		border-radius: 3px;
	}
	
	@media only screen and (max-width: 1024px) {
		#style-switcher {
			display: none;
		}
	}

/*============================================================================================*/
/* 8. UPDATES */
/*============================================================================================*/
/*-------- 8.1 Home --------*/
#carousel-home {
  position: relative;
}
#carousel-home .static{
  position: static;
}
#carousel-home .cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  max-height: none !important;
  z-index: 2;
  opacity: 1 !important;
}
#carousel-home .owl-carousel{
  position: relative;
}
#carousel-home .owl-carousel .owl-slide {
  height: 650px;
  position: relative;
}
@media (max-width: 767px) {
  #carousel-home .owl-carousel .owl-slide {
    height: 460px;
  }
}
#carousel-home .owl-carousel .owl-slide-animated {
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  -ms-transform: translateX(20px);
  -o-transform: translateX(20px);
  transform: translateX(20px);
  opacity: 0;
  visibility: hidden;
  -moz-transition: all 0.05s ease;
  -o-transition: all 0.05s ease;
  -webkit-transition: all 0.05s ease;
  -ms-transition: all 0.05s ease;
  transition: all 0.05s ease;
}
#carousel-home .owl-carousel .owl-slide-animated.is-transitioned {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  opacity: 1;
  visibility: visible;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#carousel-home .owl-carousel .owl-slide-title.is-transitioned {
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
}
#carousel-home .owl-carousel .owl-slide-subtitle.is-transitioned {
  transition-delay: 0.35s;
  -webkit-transition-delay: 0.35s;
  -moz-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
}
#carousel-home .owl-carousel .owl-slide-cta.is-transitioned {
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
}
#carousel-home .owl-carousel .owl-dots {
  bottom: 15px;
  width: 100%;
  position: absolute;
  z-index: 3;
}
#carousel-home .owl-carousel .owl-dots .owl-dot,
#carousel-home .owl-carousel .owl-nav [class*="owl-"]:focus {
  outline: none;
}
#carousel-home section {
  display: none;
}

.slide-text{
  padding-top: 30px;
}

.slide-text p{
  font-size: 21px;
  font-weight: 300;
  line-height: 1.4;
}

.slide-text h2, .slide-text h3 {
  font-size: 46px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
}
@media (max-width: 767px) {
	.slide-text {
    padding-top: 0;
}
  .slide-text h2, .slide-text h3 {
    font-size: 36px;
  }
}
.slide-text strong {
  font-weight: 700;
  font-size: 28px;
}
.slide-text.white {
  color: #fff;
}
.slide-text.white h2, .slide-text.white h3 {
  color: #fff;
}

.owl-theme .owl-dots {
  margin-top: 20 !important;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
  outline: none;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: transparent !important;
  border: 2px solid #008489;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.owl-theme .owl-dots .owl-dot span {
  position: relative;
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  background: transparent !important;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all .3s ease;
  border: 2px solid #ccc;
}
.owl-theme .owl-dots .owl-dot span::before {
  content: '';
  background-color: #008489 !important;
  display: block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -4px;
  margin-left: -4px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.owl-theme .owl-dots .owl-dot.active span::before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

#carousel-home .owl-theme .owl-dots .owl-dot.active span, #carousel-home .owl-theme .owl-dots .owl-dot:hover span {
  background: transparent !important;
  border: 2px solid #fff;
}

#carousel-home .owl-theme .owl-dots .owl-dot span {
  border: 2px solid #ccc;
}

#carousel-home .owl-theme .owl-dots .owl-dot span::before {
  background-color: #fff !important;
}

#icon_drag_mobile {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: url(../img/drag_icon.svg) 0 0 no-repeat;
  width: 50px;
  height: 30px;
  opacity: 0.6;
  z-index: 99;
  display: none;
}
@media (max-width: 767px) {
  #icon_drag_mobile {
    display: block;
  }
}

.categories_grid a {
  display: block;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .categories_grid a {
    height: 180px;
    margin-bottom: 10px;
  }
}
.categories_grid a .wrapper {
  width: 100%;
  background: transparent;
  background: -webkit-linear-gradient(top, transparent, #000);
  background: linear-gradient(to bottom, transparent, #000);
  position: absolute;
  left: 0;
  bottom: 0;
  line-height: 1;
  padding: 100px 20px 20px 20px;
}
.categories_grid a .wrapper h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 21px;
  font-size: 1.3125rem;
  margin: 0;
  -webkit-transform: translateY(20px) translateZ(0);
  -moz-transform: translateY(20px) translateZ(0);
  -ms-transform: translateY(20px) translateZ(0);
  -o-transform: translateY(20px) translateZ(0);
  transform: translateY(20px) translateZ(0);
  -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.categories_grid a .wrapper p {
  color: #fff;
  opacity: 0;
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  -webkit-transform: translateY(20px) translateZ(0);
  -moz-transform: translateY(20px) translateZ(0);
  -ms-transform: translateY(20px) translateZ(0);
  -o-transform: translateY(20px) translateZ(0);
  transform: translateY(20px) translateZ(0);
  -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.categories_grid a img {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}
.categories_grid a:hover img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.categories_grid a:hover h2 {
  -webkit-transform: translateY(-5px) translateZ(0);
  -moz-transform: translateY(-5px) translateZ(0);
  -ms-transform: translateY(-5px) translateZ(0);
  -o-transform: translateY(-5px) translateZ(0);
  transform: translateY(-5px) translateZ(0);
}
.categories_grid a:hover p {
  opacity: 0.8;
  transition-delay: 0.2s;
  -webkit-transform: translateY(0) translateZ(0);
  -moz-transform: translateY(0) translateZ(0);
  -ms-transform: translateY(0) translateZ(0);
  -o-transform: translateY(0) translateZ(0);
  transform: translateY(0) translateZ(0);
}


.owl-theme.list_carousel .item {
	padding:15px;
}

.owl-theme.list_carousel .item .tour_container, .owl-theme.list_carousel .item .hotel_container {
	margin-bottom: 0;
}

.owl-theme.list_carousel .item .ribbon_3{
	top:10px;
}
.owl-theme.list_carousel .owl-nav.disabled+.owl-dots{
	margin-top: 0;
}

.feature_home_2 {
  margin: 45px 0 25px 0;
}

.feature_home_2 h3 {
  font-size: 18px;
}

.banner_2 {
  background: url(../img/banner_bg_desktop.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 400px;
  margin-bottom: 25px;
  position: relative;
}
.banner_2 .wrapper {
  color: #fff;
  padding: 60px;
}
@media (max-width: 767px) {
  .banner_2 .wrapper {
    padding: 30px;
  }
}
.banner_2 .wrapper h3 {
  color: #fff;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 700;
}
.banner_2 .wrapper p {
  font-size: 24px;
  font-weight: 300;
}

a.box_news {
  position: relative;
  display: block;
  padding-left: 230px;
  color: #555;
  margin-bottom: 30px;
  min-height: 150px;
}
@media (max-width: 767px) {
  a.box_news {
    min-height: inherit;
    padding-left: 0;
  }
}
a.box_news figure {
  width: 200px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 767px) {
  a.box_news figure {
    position: relative;
    width: auto;
  }
}
a.box_news figure img {
  width: 250px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media (max-width: 767px) {
  a.box_news figure img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
a.box_news figure figcaption {
  background-color: #FFC107;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  color: #fff;
  width: 60px;
  height: 60px;
  padding: 15px;
  line-height: 1;
}
a.box_news figure figcaption strong {
  display: block;
  font-size: 21px;
}
a.box_news h4 {
  font-size: 18px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  a.box_news h4 {
    margin-bottom: 5px;
  }
}
a.box_news:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
a.box_news:hover h4 {
  color: #e14d67;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a.box_news ul {
  margin: 0;
  padding: 10px 0 0 0;
}
@media (max-width: 767px) {
  a.box_news ul {
    padding: 0;
  }
}
a.box_news ul li {
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  color: #999;
  font-size: 12px;
  margin-right: 5px;
}
a.box_news ul li:last-child {
  margin-right: 0;
}
a.box_news ul li:last-child:after {
  content: '';
  margin: 0;
}
a.box_news ul li:after {
  content: '-';
  margin: 0 0 0 10px;
}

.btn_home_align {
  text-align: right;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .btn_home_align {
    text-align: center;
    margin-bottom: 25px;
  }
}

.img_container_2{
  position: relative;
  height: 100%;
}

.img_container_2 .tour_container{
  position: relative;
}

.tour_container .ribbon_3{
  z-index: 9;
}

.img_container_2 .carousel_item{
	
}

.img_container_2 .short_info{
  z-index: 2;
  bottom: 0;
}

/*-------- Home Search V2--------*/
#search_container_2 {
	position: relative;
	height: 580px;
	background: #ccc url(../img/hero_3.jpg) no-repeat center top;
	background-size: cover;
	color: #fff;
	width: 100%;
	display: table;
	z-index: 99;
}

#search_2 {
	display: table-cell;
	vertical-align: middle;
	padding: 0 15%;
	padding-top: 100px;
	background: rgba(0, 0, 0, 0.4);
}

@media (max-width: 575px) {
  #search_2 {
	padding: 0 15px;
	}
}

#search_2 .nav-tabs {
	border-radius: 0;
	font-size: 12px;
	border: 0;
	border-bottom: 1px solid transparent;
	margin: 0 0 10px 0;
	text-transform: uppercase;
}

#search_2 .nav-tabs > li > a {
	border: 0;
	color: #fff !important;
	padding: 10px 15px;
	margin-right: 5px;
	-webkit-border-radius: 3px;
  	-moz-border-radius: 3px;
  	-ms-border-radius: 3px;
  	border-radius: 3px;
  	position: relative;
  	padding-left: 40px;
}

@media (max-width: 575px) {
  #search_2 .nav-tabs > li > a{
		padding:10px 15px;
	}
  #search_2 .nav-tabs > li > a:before{
		display: none;
	}
}

#search_2 .nav-tabs > li > a#tab_bt_1:before, #search_2 .nav-tabs > li > a#tab_bt_2:before, #search_2 .nav-tabs > li > a#tab_bt_3:before {
	font-family: 'fontello';
	font-size: 18px;
	margin-right: 5px;
	position: absolute;
	left: 15px;
	top:8px;
}

#search_2 .nav-tabs > li > a#tab_bt_1:before {
	content: '\e83c';
}

#search_2 .nav-tabs > li > a#tab_bt_2:before {
	content: '\ed60';
}

#search_2 .nav-tabs > li > a#tab_bt_3:before {
	content: '\ed80';
}

#search_2 .tab-content {
	padding:0;
	border:0;
	background-color: transparent;
}

#search_2 .nav-tabs > li > a {
	background: rgba(0, 0, 0, 0.40);
}

#search_2 .nav-tabs > li > a.active ,
#search_2 .nav-tabs > li > a:hover .active,
#search_2 .nav-tabs > li > a:focus .active {
	color: #fff;
	background-color: #e14d67;
}

.custom-search-input-2 {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
}
@media (max-width: 991px) {
  .custom-search-input-2 {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.custom-search-input-2 input {
  border: 0;
  height: 50px;
  padding-left: 15px;
  border-right: 1px solid #d2d8dd;
  font-weight: 500;
}
@media (max-width: 991px) {
  .custom-search-input-2 input {
    border: none;
  }
}
.custom-search-input-2 input:focus {
  box-shadow: none;
  border-right: 1px solid #d2d8dd;
}
@media (max-width: 991px) {
  .custom-search-input-2 input:focus {
    border-right: none;
  }
}
.custom-search-input-2 select {
  display: none;
}
.custom-search-input-2 .nice-select .current {
  font-weight: 500;
  color: #6f787f;
}
.custom-search-input-2 .form-group {
  margin: 0;
}
@media (max-width: 991px) {
  .custom-search-input-2 .form-group {
    margin-bottom: 5px;
  }
}
.custom-search-input-2 i {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  font-size: 1.125rem;
  position: absolute;
  background-color: #fff;
  line-height: 50px;
  top: 0;
  right: 1px;
  padding-right: 15px;
  display: block;
  width: 20px;
  box-sizing: content-box;
  height: 50px;
  z-index: 9;
  color: #999;
}
@media (max-width: 991px) {
  .custom-search-input-2 i {
    padding-right: 10px;
  }
}
.custom-search-input-2 input[type='submit'] {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  border: 0;
  padding: 0 25px;
  height: 50px;
  cursor: pointer;
  outline: none;
  width: 100%;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #e14d67;
  margin-right: -1px;
}
@media (max-width: 991px) {
  .custom-search-input-2 input[type='submit'] {
    margin: 20px 0 0 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
}
.custom-search-input-2 input[type='submit']:hover {
  background-color: #008489;
  color: #fff;
}

/* Autocomplete Address Search */
.pac-container {
  margin-top: 10px;
  border-top: none !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  border-radius: 5px !important;
  font-family: "Montserrat", Arial, sans-serif;
  color: #444;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
}
.pac-item-query {
  color: #444;
}

.panel-dropdown {
  position: relative;
  text-align: left;
  padding: 15px 10px 0 15px;
}
@media (max-width: 991px) {
  .panel-dropdown {
    background-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    height: 50px;
  }
}
.panel-dropdown a {
  color: #727b82;
  font-weight: 500;
  transition: all 0.3s;
  display: block;
  position: relative;
}
.panel-dropdown a:after {
  font-family: 'ElegantIcons';
  content: "\33";
  font-size: 26px;
  color: #999;
  font-weight: 500;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 0;
  top: 0;
}
.panel-dropdown.active a:after {
  transform: rotate(180deg);
}
.panel-dropdown .panel-dropdown-content {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  position: absolute;
  top: 58px;
  left: 0px;
  z-index: 99;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  padding: 15px 15px 0 15px;
  white-space: normal;
  width: 280px;
}
.panel-dropdown .panel-dropdown-content:after {
  bottom: 100%;
  left: 15px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 7px;
  margin-left: -7px;
}
.panel-dropdown .panel-dropdown-content.right {
  left: auto;
  right: 0;
}
.panel-dropdown .panel-dropdown-content.right:after {
  left: auto;
  right: 15px;
}

.panel-dropdown.active .panel-dropdown-content {
  opacity: 1;
  visibility: visible;
}

.qtyButtons {
  display: flex;
  margin: 0 0 13px 0;
}
.qtyButtons input {
  outline: 0;
  font-size: 16px;
  font-size: 1rem;
  text-align: center;
  width: 50px;
  height: 36px !important;
  color: #333;
  line-height: 36px;
  margin: 0 !important;
  padding: 0 5px !important;
  border: none;
  box-shadow: none;
  pointer-events: none;
  display: inline-block;
  border: none !important;
}
.qtyButtons label {
  font-weight: 500;
  line-height: 36px;
  padding-right: 15px;
  display: block;
  flex: 1;
  color: #727b82;
}

.qtyInc,
.qtyDec {
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 28px;
  font-size: 1.75rem;
  background-color: #f2f2f2;
  -webkit-text-stroke: 1px #f2f2f2;
  color: #333;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  font-family: 'ElegantIcons';
}

.qtyInc:before {
  content: "\4c";
}

.qtyDec:before {
  content: "\4b";
}

.qtyTotal {
  background-color: #66676b;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 18px;
  text-align: center;
  position: relative;
  top: 0;
  left: 2px;
  height: 18px;
  width: 18px;
}

.rotate-x {
  animation-duration: .5s;
  animation-name: rotate-x;
}

@keyframes rotate-x {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
/*-------- 8.2 Misc --------*/

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #555;
}

.owl-theme.list_carousel .owl-nav{
  position: absolute;
  top: 30%;
  transform: translateY(-30%);
  left: 0;
  width: 100%;
  margin: 0 !important;
}

.owl-theme.carousel_item .owl-nav {
  position: absolute;
  top: 35%;
  transform: translateY(-35%);
  left: 0;
  width: 100%;
  margin: 0 !important;
}

.owl-theme.list_carousel .owl-prev,
.owl-theme.list_carousel .owl-next,
.owl-theme.carousel_item .owl-prev,
.owl-theme.carousel_item .owl-next{
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  display: block !important;
}

.owl-theme.list_carousel .owl-nav [class*='owl-']:hover, .owl-theme.carousel_item .owl-nav [class*='owl-']:hover{
  background: none !important;
}

.owl-theme.list_carousel .owl-prev {
  left: -50px;
}

.owl-theme.list_carousel .owl-next {
  right: -30px;
}

.owl-theme.carousel_item .owl-prev {
  left: -5px;
}

.owl-theme.carousel_item .owl-next {
  right: 15px;
}

.owl-theme.list_carousel .owl-prev i,
.owl-theme.list_carousel .owl-next i,
.owl-theme.carousel_item .owl-prev i,
.owl-theme.carousel_item .owl-next i {
  font-size: 46px;
  color: #ccc;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.owl-theme.list_carousel .owl-prev i:hover,
.owl-theme.list_carousel .owl-next i:hover {
  color: #333;
}

.owl-theme.carousel_item .owl-next i, .owl-theme.carousel_item .owl-prev i {
  font-size: 46px;
  color: #fff;
}

.owl-theme.list_carousel .owl-prev.disabled i,
.owl-theme.list_carousel .owl-next.disabled i{
  opacity: 0;
}

.owl-theme.carousel_item .owl-prev.disabled i,
.owl-theme.carousel_item .owl-next.disabled i{
  opacity: 1;
}


.owl-theme.list_carousel .owl-prev.disabled i:hover,
.owl-theme.list_carousel .owl-next.disabled i:hover {
  color: #ccc;
}

.small-gutters {
  margin-right: -5px !important;
  margin-left: -5px !important;
}
.small-gutters > [class*="col-"] {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.opacity-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.parallax-content-1 div p {
    font-weight: 300;
    font-size: 18px;
}

.tooltip{
  line-height: 1.3;
  font-size: 12px;
}

.datepicker.dropdown-menu {
	padding:15px 20px 20px 20px!important;
    border: 1px solid #ddd!important;
    -webkit-border-radius: 3px!important;
    border-radius: 3px!important;
    box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12)!important;
}

.datepicker-dropdown:before {
    border-bottom: 7px solid #ddd!important;
    border-bottom-color: #ddd!important;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
    border-bottom: 0!important;
    border-top: 7px solid #ccc!important;
}
/*============================================================================================*/
/* 9. RESPONSIVE */
/*============================================================================================*/

/* MEDIA QUERIES size 768/1200px  */
@media (min-width: 768px) and (max-width: 1200px) {
	
	.main-menu ul li.megamenu .menu-wrapper {
		width: 940px;
	}
	
	.img_list img {
		left: -25%;
	}
	
	#search {
		padding: 0 15%;
		padding-top: 100px;
	}
}
/* END MEDIA QUERIES size 768/1200px  */

/* MEDIA QUERIES max-width 1024px  */
@media (max-width: 1024px) {
	
	.promo_full {
		background-attachment: scroll;
	}
}
/* END MEDIA QUERIES max-width 1024 px  */

/* LOGO H1 FOR RETINA DISPLAY  */
@media only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 991px),
only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 991px),
only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991px),
only screen and (min-device-pixel-ratio: 2) and (max-width: 991px) {
	
	#logo_home h1 a,
	header#colored #logo_home h1 a {
		background-image: url(../img/logo_2x.png);
		background-size: auto 30px;
	}
	
	header.sticky #logo_home h1 a,
	header#plain #logo_home h1 a {
		background-image: url(../img/logo_sticky_2x.png);
		background-size: auto 30px;
	}
	
	header.sticky#colored #logo_home h1 a {
		background-image: url(../img/logo_sticky_colored_2x.png);
		background-size: auto 30px;
	}
}
/* END LOGO H1 FOR RETINA DISPLAY  */

/* MEDIA QUERIES max-width 991px  */
@media (max-width: 991px) {
	
	ul#top_tools,
	.sticky ul#top_tools {
		margin: 3px 45px 0 0;
	}
	
	.sticky nav {
		margin-top: 3px;
	}
	
	nav {
		margin-top: 10px;
	}
	
	header#plain {
		padding-bottom: 10px;
	}
	
	header#plain.sticky {
		padding-bottom: 0;
	}
	
	header #logo img.logo_normal,
	header #logo img.logo_sticky {
		width: auto;
		height: 30px;
	}
	
	#logo_home h1 a,
	header.sticky #logo_home h1 a,
	header#plain #logo_home h1 a,
	header#colored #logo_home h1 a {
		width: 180px;
		height: 30px;
		background-size: auto 30px;
	}
	
	.main-menu ul li.megamenu .menu-wrapper {
		width: auto;
		margin-top: 20px;
	}
	
	#single_tour_feat ul {
		margin-bottom: 10px;
	}
	
	.review_strip_single.last {
		border-bottom: 1px solid #ddd;
		margin-bottom: 20px;
	}
	
	a#phone,
	a#email_footer {
		font-size: 12px;
		padding-left: 25px;
		margin: 5px 0 5px 0;
	}
	
	a#phone:before,
	a#email_footer:before {
		font-size: 18px;
	}
	a#email_footer {
		margin-bottom: 10px;
	}
	
	#search {
		padding: 0 5%;
		padding-top: 130px;
	}
	
	.plan {
		min-height: inherit;
	}
	
	.parallax-content-1 div h1,
	#hero_2 .intro_title h3 {
		font-size: 42px;
	}
	
	.parallax-content-1 div h1:before,
	.parallax-content-1 div h1:after {
		width: 30px;
	}
	
	#hero_2 .intro_title h1:before,
	#hero_2 .intro_title h1:after {
		width: 30px;
	}
	
	#map_filter ul li a {
		min-width: 100px;
	}
	
	#map_filter ul li a span {
		font-size: 11px;
	}
	.map-right #map{
		height: 450px;
	}
	
	.features-content {
		padding: 10%;
		height: auto;
	}
	
}
/* END MEDIA QUERIES max-width 991px  */

/* MEDIA QUERIES size 769/979px  */
@media (min-width: 769px) and (max-width: 979px) {
	
	#map-container {
		height: 280px;
		position: relative;
		top: 0;
		overflow: hidden;
	}
	
	#content-row {
		height: auto;
		padding-top: 0;
	}
	
	.plan-tall + .plan {
		border: solid #dddddd 1px;
	}
	
	.plan-tall {
		margin-right: 0;
	}
	
	.col-md-4.plan:first-child {
		margin-right: 0;
		margin-left: 0;
		border-right: solid #dddddd 1px;
	}
	
	.view_on_map {
		display: none;
	}
	
	.full-height {
		height: auto;
	}
	
	.row-height {
		height: auto;
		min-height: ;
	}
	
	.content-left {
		height: auto;
		min-height: ;
		overflow-y: scroll;
	}
	
}
/* END MEDIA QUERIES size 769/979px  */

/* MEDIA QUERIES max-width 768px - TABLETS  */
@media (max-width: 768px) {
	
	.parallax-content-1 div {
		padding: 0 10%;
		font-size: 14px;
	}
	
	.parallax-content-1 div h3 {
		font-size: 36px;
	}
	
	.step {
		padding: 20px 31px 20px 31px;
	}
	
	.cards {
		margin-top: 0;
		margin-bottom: 20px;
	}
	
	#single_tour_feat ul li i {
		font-size: 28px;
	}
	
	#hero_video a.video {
		display: inline-block;
	}
	
	.login_social {
		margin-bottom: 5px;
	}
	
	/* New v1.4 */
	#search {
		padding: 0 5%;
		padding-top: 100px;
	}
	
	.view_on_map {
		display: none;
	}
	
	.full-height {
		height: auto;
	}
	
	.row-height {
		height: auto;
		min-height: ;
	}
	
	.content-left {
		height: auto;
		min-height: ;
		overflow-y: scroll;
	}
	
	#newsletter_container h3 {
		font-size: 22px;
		margin: 0 0 20px 0;
	}
	
	#newsletter_container input.form-control#email_newsletter_2 {
		width: 80%;
		margin: auto;
		margin-bottom: 15px;
	}
	
	footer ul {
		margin: 0;
		padding: 0 0 0 0;
		list-style: none;
	}
	
	/* Header 6*/
 .dropdown-mini .dropdown-menu {margin-left: -75px;}
}
/* END MEDIA QUERIES max-width 768px - TABLETS  */

/* MEDIA QUERIES max-width 767px - MOBILE LANDSCAPE  */
@media (max-width: 767px) {
	
	#top_line {
		display: none;
	}
	
	.strip_all_tour_list {
		text-align: center;
	}
	
	.img_list {
		width: 100%;
		margin: auto;
	}
	
	.img_list img {
		left: 0;
		width: 100%;
		height: auto;
		top: -30%;
	}
	
	.tour_list_desc {
		padding: 0 15px;
		border-right: none;
		height: auto;
	}
	
	.price_list {
		display: block;
		height: auto;
		margin-left: 0;
		text-align: center;
		padding: 25px 0 15px 0;
	}
	
	.price_list div {
		display: block;
		text-align: center;
	}
	
	.price_list .button {
		display: block;
		margin: 15px;
	}
	
	.main_title {
		font-size: 14px;
	}
	
	.main_title h2 {
		font-size: 24px;
	}
	
	.main_title p {
		font-size: 16px
	}
	
	/* Mobile navigation for categories left column */
	.styled-select-cat select {
		background: transparent;
		width: 107%;
		padding: 7px 5px 5px 45px;
		border: 0;
		border-radius: 0;
		height: 50px;
		margin: 0;
		font-weight: 400;
		-moz-appearance: window;
		-webkit-appearance: none;
		cursor: pointer;
		color: #888;
	}
	
	.styled-select-cat {
		width: 100%;
		overflow: hidden;
		height: 50px;
		position: relative;
		border: none;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
	}
	
	.styled-select-cat:before {
		font-style: normal;
		font-weight: normal;
		font-family: "icon_set_1";
		font-size: 22px;
		content: "\53";
		position: absolute;
		left: 15px;
		top: 15px;
	}
	
	.styled-select-cat::-ms-expand
	{
		display: none;
	}
	
	.styled-select-cat select:focus {
		outline: none;
	}
	
	/* Responsive table cart */	
	.table.cart-list,
	.table.cart-list thead,
	.table.cart-list tbody,
	.table.cart-list th,
	.table.cart-list td,
	.table.cart-list tr {
		display: block;
		border: 0;
	}
	
	.table.cart-list thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	.table.cart-list tr {
		border: 1px solid #ccc;
	}
	
	.table.cart-list td {
		border: none;
		border-bottom: 1px solid #ddd;
		position: relative;
		padding-left: 50%;
	}
	
	.table.cart-list td:before {
		position: absolute;
		top: 12px;
		left: 12px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}
	
	.table.cart-list td:nth-of-type(1):before {
		content: "Item";
		font-weight: bold;
		color: #111;
	}
	
	.table.cart-list td:nth-of-type(2):before {
		content: "Quantity";
		font-weight: bold;
		color: #111;
	}
	
	.table.cart-list td:nth-of-type(3):before {
		content: "Discount";
		font-weight: bold;
		color: #111;
	}
	
	.table.cart-list td:nth-of-type(4):before {
		content: "Total";
		font-weight: bold;
		color: #111;
	}
	
	.table.cart-list td:nth-of-type(5):before {
		content: "Actions";
		font-weight: bold;
		color: #111;
	}
	
	.thumb_cart {
		display: none;
	}
	
	#single_tour_feat ul {
		display: block;
		margin-bottom: 10px;
	}
	
	#single_tour_feat ul li {
		display: inline-block;
		width: auto;
		font-size: 11px;
	}
	
	/* Margin tools */
	.margin_60 {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	
	.margin_30 {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	
	/* Parallax bg */
	.parallax-window,
	#hero_2 {
		height: 240px;
		min-height: 240px;
	}
	
	.parallax-content-1 {
		height: 240px;
	}
	
	.parallax-content-1 > div {
		padding: 0 5%;
		font-size: 12px;
		padding-top: 20px;
	}
	
	.parallax-content-1 div h1,
	.parallax-content-1 div h3 {
		font-size: 20px;
		padding-top: 15px;
	}
	
	/* Banners */
	#banner {
		padding: 20px;
	}
	
	.banner a {
		position: relative;
		top: 15px;
		right: 0;
		left: 0;
		margin-bottom: 20px;
	}
	
	.banner h4 {
		font-size: 22px;
	}
	
	/* tooltip */
	.strip_all_tour_list .tooltip-content-flip {
		width: 70px;
	}
	
	/* Single tour page */
	.parallax-content-2 {
		padding-bottom: 15px;
	}
	
	.parallax-content-2 div h1 {
		font-size: 22px;
	}
	
	.parallax-content-2 .rating {
		font-size: 12px;
		display: block;
	}
	
	#price_single_main,
	#price_single_main.hotel {
		font-size: 11px;
		text-align: left;
		padding-top: 0;
	}
	
	#price_single_main span {
		font-size: 20px;
	}
	
	#price_single_main sup {
		font-size: 20px;
		top: 0;
	}
	
	#hero_2 .intro_title h1 {
		font-size: 22px;
	}
	
	.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
		font-size: 12px;
	}
	
	#hero {
		height: 350px;
		font-size: 12px;
	}
	
	.intro_title h3 {
		font-size: 26px;
		margin-bottom: 5px;
		padding-top:30px;
		color: #fff;
		font-weight: bold;
		text-transform: uppercase;
	}
	
	.item_cart {
		margin-top: 0;
	}
	
	.intro_title.error h1 {
		font-size: 50px;
	}
	
	.radio_fix {
		margin-top: 10px
	}
	
	.col-md-4.plan:first-child,
	.col-md-4.plan:last-child {
		margin-right: 0px;
		margin-left: 0px;
		border-width: 1px 1px 1px 1px;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	
	.plan-tall + .plan {
		border-left: 1px;
		border: solid #dddddd;
	}
	
	.plan-tall {
		margin-right: 0;
	}
	
	#map_filter ul li a {
		min-width: inherit;
	}
	
	#map_filter ul li a span {
		display: none;
	}
	
	#map_filter ul li a i {
		font-size: 24px;
	}
	
	#map_contact {
		height: 300px;
	}
	
	#map_contact.contact_2 {
		margin-top: 60px;
	}
	
	.content-left {
		padding-top: 80px;
	}
	
	.map-right #map{
		height: 375px;
	}
	
	#top_line.visible_on_mobile{
		display: block;
	}
	
	a#lang_link,
	a#currency_link {
		position: relative;
		text-indent: -999px;
		width: 20px;
		height: 20px;
		display: block;
	}
	
	#lang_link:before,
	#currency_link:before {
		text-indent: 0px;
		font-size: 20px;
	}
	
	.dropdown-access .dropdown-menu:before {
		right: 5%;
	}
	
	a#access_link,
	a#wishlist_link {
		position: relative;
		text-indent: -999px;
		width: 20px;
		height: 20px;
		display: block;
	}
	
	#wishlist_link:before,
	#access_link:before {
		text-indent: 0px;
		font-size: 20px;
	}
	.search-overlay-menu form {
		width: 80%;
		left: 10%;
		height: 50px;
		margin: -25px auto 0;
	}
	.search-overlay-menu input[type="search"] {
		font-size: 26px;
	}
	.search-overlay-menu form button {
		font-size: 2rem;
		top: 10px;
	}
	
	.laptop{
		display: none;
	}
	
	.score {
		display: none;
	}
}
/* END MEDIA QUERIES max-width 767px - MOBILE LANDSCAPE  */

/* MEDIA QUERIES max-width 480px - MOBILE PORTRAIT  */
@media (max-width: 480px) {
	
	.features-slider {
		padding: 11% 50px 10% 50px;
	}
	
	.box_style_1 {
		padding: 15px;
	}
	
	.box_style_1 h3.inner {
		margin: -15px -15px 15px -15px;
	}
	
	.img_list img {
		left: 0;
		width: 100%;
		height: auto;
		top: -5%;
	}
	
	.tooltip-item::after {
		width: 180px;
	}
	
	.tooltip-content {
		width: 180px;
		margin: 0 0 20px -90px;
	}
	
	.styled-select-filters {
		margin-bottom: 5px;
	}
	
	.search_bar {
		width: 100%;
	}
	
	#hero {
		height: 250px;
	}
	
	.intro_title {
		display: none;
	}
	
	.sticky ul#top_tools, ul#top_tools  {
		margin: 3px 25px 0 0;
	}
	
	.dropdown.dropdown-cart {
		display: none;
	}
	
	.dropdown.dropdown-search {
		font-size: 20px;
	}
	
	#testimonials h2 {
		font-size: 28px;
	}
	
	#score {
		float: none;
		margin-top: 15px;
	}
	
	.intro_title.error {
		display: table-cell;
	}
	
	.intro_title.error h1 {
		font-size: 36px;
	}
	
	.intro_title.error a.button_intro {
		display: none;
	}
	
	#search {
		padding: 0 5%;
		padding-top: 100px;
	}
	
	.promo_full_wp div h3 {
		font-size: 36px;
	}
	
	.promo_full_wp div a i {
		font-size: 60px;
	}
	
	#map_filter ul li a i {
		font-size: 18px;
	}
	
	#search ul.nav.nav-tabs{
    margin-left:0;
    padding-left:0;
	margin-bottom: 1px;
	}
	
	#search .nav-tabs > li > a {
		font-size:11px;
		padding:5px 8px;
	}

	
}
/* END MEDIA QUERIES max-width 480px - MOBILE PORTRAIT  */