@charset "UTF-8";
/* CSS Document */

.noBorder {
  border: 0px;
}

h2.country-header {
  color: #008489;
}

.obe_checkbox-grey,
.iradio_square-grey {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url("../img/grey.png") no-repeat;
  border: none;
  cursor: pointer;
}

.obe_checkbox-grey.hover {
  background-position: -24px 0;
}

.obe_checkbox-grey.checked {
  background-position: -48px 0;
}

.obe_checkbox-grey.disabled {
  background-position: -72px 0;
  cursor: default;
}

.obe_checkbox-grey.checked.disabled {
  background-position: -96px 0;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #1d8488;
  border-color: #1d8488;
}

.page-item .page-link:hover {
  background-color: #1d8488;
  border-color: #1d8488;
  color: #fff;
}
